import { usePathname, useSearchParams } from "next/navigation";
import { createContext, useContext, useEffect, useRef } from "react";

export type NavigationState = {
	previousUrl: string | null;
};

export const NavigationContext = createContext<NavigationState>({
	previousUrl: null,
});

export const NavigationProvider = ({ children }: { children: React.ReactNode }) => {
	const pathname = usePathname();
	const searchParams = useSearchParams();
	const previousUrlRef = useRef<string | null>(null);

	useEffect(() => {
		previousUrlRef.current = window.location.href;
	}, [pathname, searchParams]);

	const value = {
		previousUrl: previousUrlRef?.current,
	};

	return <NavigationContext.Provider value={value}>{children}</NavigationContext.Provider>;
};

export const useNavigation = () => useContext(NavigationContext);
