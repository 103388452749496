/* eslint-disable */
import * as types from './graphql';



/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n\tmutation StoreContentPreviewData($path: String!, $data: String!) {\n\t\tstoreContentPreview(data: $data, path: $path) {\n\t\t\tid\n\t\t}\n\t}\n": types.StoreContentPreviewDataDocument,
    "\n\tfragment FooterNewsletterFragment on Site {\n\t\tshowNewsletterCTA\n\t\tnewsletterCTATitle\n\t\tnewsletterCTABody\n\t}\n": types.FooterNewsletterFragmentFragmentDoc,
    "\n\tmutation NewsletterSignUp($storeContext: StoreContextInput!, $email: String!) {\n\t\tnewsletterSignUp(storeContext: $storeContext, email: $email)\n\t}\n": types.NewsletterSignUpDocument,
    "\n\tquery GetCompanyReview($storeContext: StoreContextInput!) {\n\t\tcompanyReview(storeContext: $storeContext) {\n\t\t\tamount\n\t\t\ttotalAmount\n\t\t\taverageRating\n\t\t}\n\t}\n": types.GetCompanyReviewDocument,
    "\n\tquery GetSite($storeContext: StoreContextInput!, $version: ContentVersion!, $previewID: String) {\n\t\tsite(storeContext: $storeContext, version: $version, previewID: $previewID) {\n\t\t\t...Site\n\t\t}\n\t}\n": types.GetSiteDocument,
    "\n\tfragment Site on Site {\n\t\t__typename\n\t\tid\n\n\t\tlogo {\n\t\t\t...ImageAsset\n\t\t}\n\n\t\torganizationAddress {\n\t\t\tpostalCode\n\t\t\tlocality\n\t\t\tcountry\n\t\t\tstreet\n\t\t}\n\n\t\tfooterLogo {\n\t\t\t...ImageAsset\n\t\t}\n\n\t\tmainMenu {\n\t\t\t...MainMenu\n\t\t\t...MenuLink\n\t\t}\n\n\t\tfooterMenu {\n\t\t\t...MenuBlock\n\t\t}\n\n\t\tpaymentMethods {\n\t\t\t...ImageAsset\n\t\t}\n\n\t\tcustomerServiceEmail\n\t\tcustomerServiceHours\n\t\tcustomerServicePhone\n\t\tfaqItems {\n\t\t\t...FAQItemFragment\n\t\t}\n\n\t\ttrustMarks {\n\t\t\t...ImageAsset\n\t\t}\n\n\t\tcarriers {\n\t\t\t...ImageAsset\n\t\t}\n\n\t\tpaymentTerms {\n\t\t\t...RichTextFragment\n\t\t}\n\n\t\tsocialsHeading\n\t\tfacebook {\n\t\t\turl\n\t\t}\n\t\tinstagram {\n\t\t\turl\n\t\t}\n\t\tlinkedIn {\n\t\t\turl\n\t\t}\n\t\tyoutube {\n\t\t\turl\n\t\t}\n\t\ttiktok {\n\t\t\turl\n\t\t}\n\t\tpinterest {\n\t\t\turl\n\t\t}\n\n\t\tbusinessLabel\n\t\tbusinessPage {\n\t\t\turl\n\t\t\tnewTab\n\t\t}\n\t\tserviceLabel\n\t\tservicePage {\n\t\t\turl\n\t\t\tnewTab\n\t\t}\n\t\tdisclaimerLabel\n\t\tdisclaimerPage {\n\t\t\turl\n\t\t\tnewTab\n\t\t}\n\t\ttermsLabel\n\t\ttermsPage {\n\t\t\turl\n\t\t\tnewTab\n\t\t}\n\t\tcookiesLabel\n\t\tcookiesPage {\n\t\t\turl\n\t\t\tnewTab\n\t\t}\n\t\tprivacyLabel\n\t\tprivacyPage {\n\t\t\turl\n\t\t\tnewTab\n\t\t}\n\t\tusps {\n\t\t\t...UspsFragment\n\t\t}\n\n\t\tnotificationBanners {\n\t\t\t...NotificationBanner\n\t\t}\n\n\t\t...FooterNewsletterFragment\n\t}\n": types.SiteFragmentDoc,
    "\n\tfragment MainMenu on MenuItem {\n\t\t...MenuItemFields\n\t\tsubMenuItems {\n\t\t\t...MenuItemFields\n\t\t\t...MenuLink\n\t\t\t... on MenuItem {\n\t\t\t\tsubMenuItems {\n\t\t\t\t\t...MenuItemFields\n\t\t\t\t\t...MenuLink\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n": types.MainMenuFragmentDoc,
    "\n\tfragment MenuItemFields on MenuItem {\n\t\t__typename\n\t\tid\n\t\tlabel\n\t\toptionalLink: link {\n\t\t\turl\n\t\t}\n\t}\n": types.MenuItemFieldsFragmentDoc,
    "\n\tfragment MenuLink on MenuLink {\n\t\t__typename\n\t\tid\n\t\tlabel\n\t\tlink {\n\t\t\turl\n\t\t\tnewTab\n\t\t}\n\t}\n": types.MenuLinkFragmentDoc,
    "\n\tfragment MenuBlock on MenuBlock {\n\t\t__typename\n\t\tid\n\t\tlabel\n\t\tmenuLinks {\n\t\t\t...MenuLink\n\t\t}\n\t}\n": types.MenuBlockFragmentDoc,
    "\n\tfragment NotificationBanner on NotificationBanner {\n\t\t__typename\n\t\tid\n\t\ttext\n\t\tlinkLabel\n\t\toptionalLink: link {\n\t\t\turl\n\t\t}\n\t}\n": types.NotificationBannerFragmentDoc,
    "\n\tmutation CustomerPasswordReset(\n\t\t$storeContext: StoreContextInput!\n\t\t$password: String!\n\t\t$token: String!\n\t) {\n\t\tcustomerPasswordReset(storeContext: $storeContext, password: $password, token: $token) {\n\t\t\t...Customer\n\t\t}\n\t}\n": types.CustomerPasswordResetDocument,
    "\n\tmutation CustomerPasswordResetRequest($storeContext: StoreContextInput!, $email: String!) {\n\t\tcustomerPasswordResetRequest(storeContext: $storeContext, email: $email)\n\t}\n": types.CustomerPasswordResetRequestDocument,
    "\n\tmutation CustomerCreate($storeContext: StoreContextInput!, $customer: CustomerCreateInput!) {\n\t\tcustomerCreate(storeContext: $storeContext, customer: $customer) {\n\t\t\t...Customer\n\t\t}\n\t}\n": types.CustomerCreateDocument,
    "\n\tmutation CustomerPasswordUpdate(\n\t\t$storeContext: StoreContextInput!\n\t\t$password: String!\n\t\t$newPassword: String!\n\t) {\n\t\tcustomerPasswordUpdate(\n\t\t\tstoreContext: $storeContext\n\t\t\tpassword: $password\n\t\t\tnewPassword: $newPassword\n\t\t) {\n\t\t\t...Customer\n\t\t}\n\t}\n": types.CustomerPasswordUpdateDocument,
    "\n\tfragment AbstractPageFragment on AbstractPage {\n\t\t__typename\n\t\tid\n\t\tname\n\t\tpath\n\t\ttranslatedPaths {\n\t\t\tlocale\n\t\t\tpath\n\t\t}\n\t\tbreadcrumbs {\n\t\t\t...BreadcrumbFragment\n\t\t}\n\t\tseo {\n\t\t\t...ContentSEOFragment\n\t\t}\n\t}\n": types.AbstractPageFragmentFragmentDoc,
    "\n\tfragment ContentSEOFragment on ContentSEO {\n\t\t__typename\n\t\ttitle\n\t\tdescription\n\t\togTitle\n\t\togDescription\n\t\togImage\n\t\ttwitterTitle\n\t\ttwitterDescription\n\t\ttwitterImage\n\t}\n": types.ContentSeoFragmentFragmentDoc,
    "\n\tfragment ContentPageFragment on ContentPage {\n\t\t...AbstractPageFragment\n\t\tcontentPageTitle: title\n\t\tdescription\n\n\t\thero {\n\t\t\t...HeroFragment\n\t\t}\n\t\tusps {\n\t\t\t...UspsFragment\n\t\t}\n\t\tbody {\n\t\t\t...BlocksFragment\n\t\t}\n\t}\n": types.ContentPageFragmentFragmentDoc,
    "\n\tfragment FAQPageFragment on FAQPage {\n\t\t...AbstractPageFragment\n\n\t\ttitle\n\n\t\tfaqGroups {\n\t\t\t...FAQGroupFragment\n\n\t\t\ttranslatedPaths {\n\t\t\t\tlocale\n\t\t\t\tpath\n\t\t\t}\n\t\t}\n\t}\n": types.FaqPageFragmentFragmentDoc,
    "\n\tfragment BaseBlocksFragment on Block {\n\t\t...IframeFragment\n\t\t...SectionFragment\n\t\t...TeaserRowFragment\n\t\t...RichTextFragment\n\t\t...TwoColumnFragment\n\t\t...ThreeColumnFragment\n\t\t...FourColumnFragment\n\t\t...SectionHeaderFragment\n\t\t...DividerFragment\n\t\t...ImageFragment\n\t\t...ButtonsFragment\n\t\t...BlockQuoteFragment\n\t\t...CategorySectionFragment\n\t\t...TableFragment\n\t\t...NewsletterCTAFragment\n\t\t...UGCFlowboxFragment\n\t\t...ShopSectionFragment\n\t\t...CookieDeclarationFragment\n\t\t...ArticleOverviewFragment\n\t\t...VideoFragment\n\t\t...FAQItemListFragment\n\t}\n": types.BaseBlocksFragmentFragmentDoc,
    "\n\tfragment BlocksFragment on Block {\n\t\t...BaseBlocksFragment\n\t\t...CountrySpecificContainerFragment\n\t}\n": types.BlocksFragmentFragmentDoc,
    "\n\tfragment ProductListingPageFragment on ProductListingPage {\n\t\t...AbstractPageFragment\n\t\tplpTitle: title\n\t\tdescription\n\t\thero {\n\t\t\t...HeroFragment\n\t\t}\n\t\tafterBlocks {\n\t\t\t...BlocksFragment\n\t\t}\n\t\tbeforeBlocks {\n\t\t\t...BlocksFragment\n\t\t}\n\t\tcategory {\n\t\t\tid\n\t\t}\n\t\tproductListingConfig {\n\t\t\t...ProductListingConfig\n\t\t}\n\t}\n": types.ProductListingPageFragmentFragmentDoc,
    "\n\tfragment ArticlePageFragment on ArticlePage {\n\t\t...AbstractPageFragment\n\n\t\ttitle\n\t\timage {\n\t\t\tfilename\n\t\t\talt\n\t\t}\n\t\tlocation\n\t\tpublishedAt\n\t\tbody {\n\t\t\t...TwoColumnFragment\n\t\t\t...ThreeColumnFragment\n\t\t\t...FourColumnFragment\n\t\t\t...BlockQuoteFragment\n\t\t\t...ButtonsFragment\n\t\t\t...ImageFragment\n\t\t\t...RichTextFragment\n\t\t\t...TableFragment\n\t\t\t...VideoFragment\n\t\t\t...FAQItemListFragment\n\t\t}\n\t}\n": types.ArticlePageFragmentFragmentDoc,
    "\n\tquery PageRedirect(\n\t\t$path: String!\n\t\t$storeContext: StoreContextInput!\n\t\t$pageType: PageRedirectPageType!\n\t) {\n\t\tpageRedirect(path: $path, storeContext: $storeContext, pageType: $pageType) {\n\t\t\tdestination\n\t\t\tstatusCode\n\t\t}\n\t}\n": types.PageRedirectDocument,
    "\n\tquery GetContentPage(\n\t\t$storeContext: StoreContextInput!\n\t\t$path: String!\n\t\t$previewID: String\n\t\t$version: ContentVersion\n\t\t$currentPage: Int\n\t) {\n\t\tpage(\n\t\t\tstoreContext: $storeContext\n\t\t\tpath: $path\n\t\t\tpreviewID: $previewID\n\t\t\tversion: $version\n\t\t\tcurrentPage: $currentPage\n\t\t) {\n\t\t\t...ContentPageFragment\n\t\t\t...ProductListingPageFragment\n\t\t\t...ArticlePageFragment\n\t\t\t...FAQPageFragment\n\t\t}\n\t}\n": types.GetContentPageDocument,
    "\n\tfragment FAQGroupFragment on FAQGroup {\n\t\tid\n\t\ttitle\n\t\tslug\n\n\t\ticon {\n\t\t\t...ImageAsset\n\t\t}\n\n\t\tleadText {\n\t\t\t...RichTextFragment\n\t\t}\n\n\t\tfaqItems {\n\t\t\t...FAQItemFragment\n\t\t}\n\t}\n": types.FaqGroupFragmentFragmentDoc,
    "\n\tfragment ProductBuySectionFragment on ProductVariant {\n\t\tsku\n\t\tavailability\n\t\tavailabilityQuantity\n\t\tprice {\n\t\t\tgross {\n\t\t\t\t...Price\n\t\t\t}\n\t\t}\n\t\tregularPrice {\n\t\t\tgross {\n\t\t\t\t...Price\n\t\t\t}\n\t\t}\n\t}\n": types.ProductBuySectionFragmentFragmentDoc,
    "\n\tfragment ProductDetailPageFragment on Product {\n\t\tmeta {\n\t\t\ttitle\n\t\t\tdescription\n\t\t}\n\n\t\tusps {\n\t\t\t...UspsFragment\n\t\t}\n\n\t\tname\n\t\tslug\n\t\tavailability\n\t\tneckline\n\t\trawMaterial\n\t\tbrand\n\t\tdenier\n\t\tfit\n\t\tpackSize\n\n\t\tbrand\n\t\tribbon {\n\t\t\tkind\n\t\t\tlabel\n\t\t}\n\n\t\tprimaryVariant {\n\t\t\tsku\n\t\t\timages {\n\t\t\t\turl\n\t\t\t\talt\n\t\t\t}\n\t\t\t...ProductVariantAnalyticsFragment\n\t\t}\n\n\t\tprimaryCategory {\n\t\t\tid\n\t\t\tname\n\t\t\tslug\n\t\t\tancestors {\n\t\t\t\tstoryblokName\n\t\t\t\tname\n\t\t\t\tid\n\t\t\t}\n\t\t}\n\n\t\tvariants {\n\t\t\t...ProductVariantAnalyticsFragment\n\t\t\t...ProductVariantFragment\n\t\t\t...ProductBuySectionFragment\n\t\t}\n\n\t\trelatedProducts {\n\t\t\tid\n\t\t\tslug\n\t\t\tavailability\n\t\t\timage {\n\t\t\t\turl\n\t\t\t\talt\n\t\t\t}\n\t\t\tprimaryVariant {\n\t\t\t\tsku\n\t\t\t}\n\t\t\tvariants {\n\t\t\t\tavailability\n\t\t\t\tprice {\n\t\t\t\t\tgross {\n\t\t\t\t\t\tcentAmount\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t\tstartingPrice {\n\t\t\t\tgross {\n\t\t\t\t\t...Price\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\n\t\tstartingPrice {\n\t\t\tgross {\n\t\t\t\t...Price\n\t\t\t}\n\t\t}\n\n\t\tinstructions {\n\t\t\tkey\n\t\t\tlabel\n\t\t}\n\n\t\tmaterials {\n\t\t\tbase\n\t\t\toutside\n\t\t\tfilling\n\t\t\tlining\n\t\t}\n\n\t\treviews {\n\t\t\tamount\n\t\t\taverageRating\n\t\t\tlowestRating\n\t\t\thighestRating\n\t\t}\n\n\t\tsizeGuide {\n\t\t\t...TableFragment\n\t\t\t...ImageFragment\n\t\t\t...RichTextFragment\n\t\t\t...CountrySpecificContainerFragment\n\t\t}\n\n\t\tbreadcrumbs {\n\t\t\t...BreadcrumbFragment\n\t\t}\n\n\t\t...ProductAnalyticsFragment\n\t}\n": types.ProductDetailPageFragmentFragmentDoc,
    "\n\tfragment ProductVariantFragment on ProductVariant {\n\t\tid\n\t\tname\n\t\tsku\n\t\tavailability\n\t\timages {\n\t\t\turl\n\t\t\talt\n\t\t}\n\n\t\t# only needed for the main product\n\t\tdeliveryTime\n\t\tdescription\n\t\tattributes {\n\t\t\tname\n\t\t\tlabel\n\t\t\tvalue\n\t\t}\n\t\torderConfiguration {\n\t\t\tmin\n\t\t\tmax\n\t\t\tstepSize\n\t\t}\n\t}\n": types.ProductVariantFragmentFragmentDoc,
    "\n\tquery GetProductDetailPage($storeContext: StoreContextInput!, $slug: String!, $currentPage: Int) {\n\t\tproduct(storeContext: $storeContext, slug: $slug) {\n\t\t\t...ProductDetailPageFragment\n\n\t\t\tlocalizedSlugs {\n\t\t\t\tlocale\n\t\t\t\tslug\n\t\t\t}\n\t\t}\n\t}\n": types.GetProductDetailPageDocument,
    "\n\tfragment StoreFragment on Store {\n\t\tslug\n\t\taddress {\n\t\t\taddressLine\n\t\t\tcity\n\t\t\tdistrict\n\t\t\tphone\n\t\t\tzipcode\n\t\t\tstreetNumber\n\t\t\tcountryId\n\t\t}\n\t\tname\n\t\tslug\n\t\tstoreCode\n\t\topeningHours {\n\t\t\tfridayclose1\n\t\t\tfridayclose2\n\t\t\tfridayopen1\n\t\t\tfridayopen2\n\t\t\tmondayclose1\n\t\t\tmondayclose2\n\t\t\tmondayopen1\n\t\t\tmondayopen2\n\t\t\tsaturdayclose1\n\t\t\tsaturdayclose2\n\t\t\tsaturdayopen1\n\t\t\tsaturdayopen2\n\t\t\tsundayclose1\n\t\t\tsundayclose2\n\t\t\tsundayopen1\n\t\t\tsundayopen2\n\t\t\tthursdayclose1\n\t\t\tthursdayclose2\n\t\t\tthursdayopen1\n\t\t\tthursdayopen2\n\t\t\ttuesdayclose1\n\t\t\ttuesdayclose2\n\t\t\ttuesdayopen1\n\t\t\ttuesdayopen2\n\t\t\twednesdayclose1\n\t\t\twednesdayclose2\n\t\t\twednesdayopen1\n\t\t\twednesdayopen2\n\t\t}\n\t\tcoordinates {\n\t\t\tlat\n\t\t\tlng\n\t\t}\n\t\tnearbyStores {\n\t\t\tname\n\t\t\tslug\n\t\t\taddress {\n\t\t\t\taddressLine\n\t\t\t\tcity\n\t\t\t\tstreetNumber\n\t\t\t\tphone\n\t\t\t\tzipcode\n\t\t\t}\n\t\t\tdistanceInMeters\n\t\t\tobjectID\n\t\t\tstoreCode\n\t\t}\n\t}\n": types.StoreFragmentFragmentDoc,
    "\n\tquery GetStore($slug: String!) {\n\t\tstore(slug: $slug) {\n\t\t\t...StoreFragment\n\t\t}\n\t}\n": types.GetStoreDocument,
    "\n\tfragment CheckoutSummaryFragment on Cart {\n\t\t...CartSummaryFragment\n\n\t\tlineItems {\n\t\t\t...LineItem\n\n\t\t\tid\n\t\t\tquantity\n\t\t\tproductSlug\n\t\t\tproductName\n\t\t\taddedAt\n\t\t\tvariant {\n\t\t\t\tname\n\t\t\t\tsku\n\t\t\t\tprimaryImage {\n\t\t\t\t\turl\n\t\t\t\t\talt\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n": types.CheckoutSummaryFragmentFragmentDoc,
    "\n\tfragment OrderDetail on Order {\n\t\tcustomerEmail\n\t\torderNumber\n\t\tshippingAddress {\n\t\t\tfirstName\n\t\t\tlastName\n\t\t\tstreetName\n\t\t\thouseNumber\n\t\t\thouseNumberSuffix\n\t\t\tpostalCode\n\t\t\tcity\n\t\t\tphone\n\t\t}\n\t\tshippingCosts {\n\t\t\t...Price\n\t\t}\n\t\testimatedMaxDeliveryDays\n\t\tlineItems {\n\t\t\tid\n\t\t\tquantity\n\t\t\tproductId\n\t\t\tproductSlug\n\t\t\tproductName\n\t\t\tcategory {\n\t\t\t\tancestors {\n\t\t\t\t\tstoryblokName\n\t\t\t\t\tname\n\t\t\t\t}\n\t\t\t}\n\t\t\tprice {\n\t\t\t\tgross {\n\t\t\t\t\t...Price\n\t\t\t\t}\n\t\t\t}\n\t\t\tvariant {\n\t\t\t\tname\n\t\t\t\tsize\n\t\t\t\tcolor\n\t\t\t\tsku\n\t\t\t\tprimaryImage {\n\t\t\t\t\turl\n\t\t\t\t\talt\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t\tcurrency\n\t\ttotal {\n\t\t\tgross {\n\t\t\t\t...Price\n\t\t\t}\n\t\t}\n\t\tselectedPaymentMethod {\n\t\t\t...PaymentMethod\n\t\t}\n\t\tshippingMethod\n\t\tshippingOption\n\t}\n": types.OrderDetailFragmentDoc,
    "\n\tquery Order($storeContext: StoreContextInput!, $orderId: String!) {\n\t\torderConfirmation(storeContext: $storeContext, orderId: $orderId) {\n\t\t\t...OrderDetail\n\t\t}\n\t}\n": types.OrderDocument,
    "\n\tquery GetCheckoutDelivery($storeContext: StoreContextInput!) {\n\t\tcart(storeContext: $storeContext) {\n\t\t\tpaazlCheckoutConfig {\n\t\t\t\t...PaazlConfigFragment\n\t\t\t}\n\t\t}\n\t}\n": types.GetCheckoutDeliveryDocument,
    "\n\tfragment PaazlConfigFragment on PaazlCheckoutConfig {\n\t\tconfigObject\n\t}\n": types.PaazlConfigFragmentFragmentDoc,
    "\n\tfragment AddressFragment on Address {\n\t\tid\n\t\temail\n\t\tfirstName\n\t\tlastName\n\t\tstreetName\n\t\thouseNumber\n\t\thouseNumberSuffix\n\t\tpostalCode\n\t\tcity\n\t\tphone\n\t\tcountry\n\t\tcompany\n\t\tvatNumber\n\t}\n": types.AddressFragmentFragmentDoc,
    "\n\tmutation CustomerAddressUpdate($storeContext: StoreContextInput!, $address: AddressUpdateInput!) {\n\t\tcustomerInformationUpdate(storeContext: $storeContext, address: $address) {\n\t\t\t...Customer\n\t\t}\n\t}\n": types.CustomerAddressUpdateDocument,
    "\n\tquery GetCheckoutInformation($storeContext: StoreContextInput!) {\n\t\tcart(storeContext: $storeContext) {\n\t\t\tcustomerEmail\n\t\t\tbillingAddress {\n\t\t\t\t...AddressFragment\n\t\t\t}\n\t\t}\n\t}\n": types.GetCheckoutInformationDocument,
    "\n\tmutation UpdateCheckoutInformation(\n\t\t$storeContext: StoreContextInput!\n\t\t$setBillingAddress: AddressInput\n\t\t$setShippingAddress: AddressInput\n\t\t$setCustomerEmail: String\n\t) {\n\t\tcheckoutUpdate(\n\t\t\tstoreContext: $storeContext\n\t\t\tsetBillingAddress: $setBillingAddress\n\t\t\tsetShippingAddress: $setShippingAddress\n\t\t\tsetCustomerEmail: $setCustomerEmail\n\t\t) {\n\t\t\tcustomerEmail\n\t\t\tshippingAddress {\n\t\t\t\t...AddressFragment\n\t\t\t}\n\t\t\tbillingAddress {\n\t\t\t\t...AddressFragment\n\t\t\t}\n\t\t}\n\t}\n": types.UpdateCheckoutInformationDocument,
    "\n\tmutation UpdateCheckoutLogin(\n\t\t$storeContext: StoreContextInput!\n\t\t$id: CartIdentifierInput!\n\t\t$setCustomerEmail: String\n\t\t$setBillingAddress: AddressInput\n\t) {\n\t\tcheckoutUpdate(\n\t\t\tstoreContext: $storeContext\n\t\t\tid: $id\n\t\t\tsetCustomerEmail: $setCustomerEmail\n\t\t\tsetBillingAddress: $setBillingAddress\n\t\t) {\n\t\t\tid {\n\t\t\t\tid\n\t\t\t\tversion\n\t\t\t}\n\t\t}\n\t}\n": types.UpdateCheckoutLoginDocument,
    "\n\tmutation CheckoutCustomerLogin(\n\t\t$storeContext: StoreContextInput!\n\t\t$email: String!\n\t\t$password: String!\n\t) {\n\t\tcustomerLogin(storeContext: $storeContext, email: $email, password: $password) {\n\t\t\t...Customer\n\t\t}\n\t}\n": types.CheckoutCustomerLoginDocument,
    "\n\tquery GetCheckoutLogin($storeContext: StoreContextInput!) {\n\t\tcart(storeContext: $storeContext) {\n\t\t\tid {\n\t\t\t\tid\n\t\t\t\tversion\n\t\t\t}\n\n\t\t\tcustomerEmail\n\t\t}\n\t}\n": types.GetCheckoutLoginDocument,
    "\n\tfragment FailedOrderDetail on Order {\n\t\t...OrderDetail\n\n\t\tlineItems {\n\t\t\tid\n\t\t\tquantity\n\t\t\tproductId\n\t\t\tproductSlug\n\t\t\tproductName\n\t\t\taddedAt\n\t\t\tcategory {\n\t\t\t\tancestors {\n\t\t\t\t\tstoryblokName\n\t\t\t\t\tname\n\t\t\t\t}\n\t\t\t}\n\t\t\tprice {\n\t\t\t\tgross {\n\t\t\t\t\t...Price\n\t\t\t\t}\n\t\t\t}\n\t\t\tvariant {\n\t\t\t\tname\n\t\t\t\tsku\n\t\t\t\tprimaryImage {\n\t\t\t\t\turl\n\t\t\t\t\talt\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\n\t\tsubTotal {\n\t\t\tgross {\n\t\t\t\t...Price\n\t\t\t}\n\t\t}\n\n\t\ttotal {\n\t\t\tgross {\n\t\t\t\t...Price\n\t\t\t}\n\t\t}\n\n\t\tshippingCosts {\n\t\t\t...Price\n\t\t}\n\n\t\tselectedPaymentMethod {\n\t\t\t...PaymentMethod\n\t\t}\n\n\t\tshippingOption\n\t\tpaymentStatus\n\t}\n": types.FailedOrderDetailFragmentDoc,
    "\n\tquery FailedOrder($storeContext: StoreContextInput!, $orderId: String!) {\n\t\torder(storeContext: $storeContext, orderId: $orderId) {\n\t\t\t...FailedOrderDetail\n\t\t}\n\n\t\tavailablePaymentMethods(storeContext: $storeContext, orderId: $orderId, device: web) {\n\t\t\tname\n\t\t\tpaymentMethod {\n\t\t\t\t...PaymentMethod\n\t\t\t}\n\t\t}\n\t}\n": types.FailedOrderDocument,
    "\n\tquery GetOrderPaymentStatus($orderId: String!, $storeContext: StoreContextInput!) {\n\t\torderPaymentStatus(orderId: $orderId, storeContext: $storeContext)\n\t}\n": types.GetOrderPaymentStatusDocument,
    "\n\tmutation PaymentCreate($storeContext: StoreContextInput!, $paymentContext: PaymentContextInput!) {\n\t\tcreatePayment(storeContext: $storeContext, paymentContext: $paymentContext) {\n\t\t\tredirectURL\n\t\t\tadditionalData\n\t\t\tmethod\n\t\t}\n\t}\n": types.PaymentCreateDocument,
    "\n\tquery GetCheckoutPayment($storeContext: StoreContextInput!) {\n\t\tcart(storeContext: $storeContext) {\n\t\t\tavailablePaymentMethods(device: web) {\n\t\t\t\tname\n\t\t\t\tpaymentMethod {\n\t\t\t\t\t...PaymentMethod\n\t\t\t\t}\n\t\t\t}\n\t\t\tselectedPaymentMethod {\n\t\t\t\t...PaymentMethod\n\t\t\t}\n\t\t}\n\t}\n": types.GetCheckoutPaymentDocument,
    "\n\tfragment PaymentMethod on PaymentMethod {\n\t\tid\n\t\tname\n\t\tprovider\n\t\tissuers {\n\t\t\tid\n\t\t\tname\n\t\t}\n\t}\n": types.PaymentMethodFragmentDoc,
    "\n\tmutation CreateOrder($storeContext: StoreContextInput!) {\n\t\tcheckoutComplete(storeContext: $storeContext) {\n\t\t\torderId\n\t\t\torderNumber\n\t\t}\n\t}\n": types.CreateOrderDocument,
    "\n\tquery GetContentSlugs(\n\t\t$storeContext: StoreContextInput!\n\t\t$pageSize: Int!\n\t\t$page: Int!\n\t\t$includeCountryUnique: Boolean!\n\t\t$includeShared: Boolean!\n\t) {\n\t\tpages(\n\t\t\tstoreContext: $storeContext\n\t\t\tpageSize: $pageSize\n\t\t\tpage: $page\n\t\t\tversion: published\n\t\t\tincludeCountryUnique: $includeCountryUnique\n\t\t\tincludeShared: $includeShared\n\t\t) {\n\t\t\tresults {\n\t\t\t\t... on AbstractPage {\n\t\t\t\t\t__typename\n\t\t\t\t\tname\n\t\t\t\t\tpath\n\t\t\t\t}\n\t\t\t}\n\t\t\ttotal\n\t\t\tmoreResultsCountryUnique\n\t\t\tmoreResultsShared\n\t\t}\n\t}\n": types.GetContentSlugsDocument,
    "\n\tquery GetProductSlugs($storeContext: StoreContextInput!, $pageSize: Int!, $fromId: String) {\n\t\tproducts(storeContext: $storeContext, fromId: $fromId, pageSize: $pageSize) {\n\t\t\tlastId\n\t\t\ttotal\n\t\t\tresults {\n\t\t\t\tslug\n\t\t\t}\n\t\t}\n\t}\n": types.GetProductSlugsDocument,
    "\n\tfragment ArticleCardFragment on ArticleCard {\n\t\tid\n\t\ttitle\n\t\tlocation\n\t\tpublishedAt\n\t\timage {\n\t\t\tfilename\n\t\t\talt\n\t\t}\n\t\tlink {\n\t\t\turl\n\t\t}\n\t}\n": types.ArticleCardFragmentFragmentDoc,
    "\n\tfragment ImageAsset on ContentAsset {\n\t\tfilename\n\t\talt\n\t\tfocus\n\t}\n": types.ImageAssetFragmentDoc,
    "\n\tfragment BreadcrumbFragment on Breadcrumb {\n\t\tname\n\t\tpath\n\t}\n": types.BreadcrumbFragmentFragmentDoc,
    "\n\tfragment CartSummaryFragment on Cart {\n\t\tsubTotal {\n\t\t\t...Price\n\t\t}\n\t\tdiscount {\n\t\t\tamount {\n\t\t\t\t...Price\n\t\t\t}\n\t\t}\n\t\ttotal {\n\t\t\t...Price\n\t\t}\n\t\tsubTotalBeforeDiscount {\n\t\t\t...Price\n\t\t}\n\t\tshippingCosts {\n\t\t\t...Price\n\t\t}\n\t}\n": types.CartSummaryFragmentFragmentDoc,
    "\n\tfragment CategoryCardFragment on Card {\n\t\t__typename\n\t\tid\n\t\ttitle\n\t\tcategory\n\t\tsupportingText\n\t\timage {\n\t\t\t...ImageAsset\n\t\t}\n\t\tcardLink: link {\n\t\t\turl\n\t\t\tnewTab\n\t\t\thash\n\t\t}\n\t}\n": types.CategoryCardFragmentFragmentDoc,
    "\n\tfragment FAQItemFragment on FAQItem {\n\t\t__typename\n\t\tid\n\t\tslug\n\t\ttitle\n\n\t\trichContent: content {\n\t\t\t...RichTextFragment\n\t\t}\n\t}\n": types.FaqItemFragmentFragmentDoc,
    "\n\tfragment FacetValueFragment on FacetValue {\n\t\tkey\n\t\tlabel\n\t\tcount\n\t}\n": types.FacetValueFragmentFragmentDoc,
    "\n\tfragment RangeFacetFragment on RangeFacet {\n\t\t__typename\n\t\tkey\n\t\tlabel\n\t\tmin\n\t\tmax\n\t\tselectedMin\n\t\tselectedMax\n\t}\n\n\tfragment OptionsFacetFragment on OptionsFacet {\n\t\t__typename\n\t\tkey\n\t\tlabel\n\t\toptions {\n\t\t\t...FacetValueFragment\n\t\t}\n\t}\n\n\tfragment ToggleFacetFragment on ToggleFacet {\n\t\t__typename\n\t\tkey\n\t\tlabel\n\t\toptions {\n\t\t\t...FacetValueFragment\n\t\t}\n\t}\n": types.RangeFacetFragmentFragmentDoc,
    "\n\tfragment FacetFragment on Facet {\n\t\t...RangeFacetFragment\n\t\t...OptionsFacetFragment\n\t\t...ToggleFacetFragment\n\t}\n": types.FacetFragmentFragmentDoc,
    "\n\tfragment HeroFragment on Hero {\n\t\t__typename\n\t\tid\n\t\ttitle\n\t\tvariant\n\t\timagePosition\n\t\timageStyle\n\t\tbodyText {\n\t\t\t...RichTextFragment\n\t\t}\n\t\tbuttons {\n\t\t\t...ButtonFragment\n\t\t}\n\t\timage {\n\t\t\t...ImageAsset\n\t\t}\n\t}\n": types.HeroFragmentFragmentDoc,
    "\n\tfragment LineItemSummaryFragment on LineItem {\n\t\tid\n\t\tquantity\n\t\tproductName\n\t\tvariant {\n\t\t\tname\n\t\t\tcolor\n\t\t\tsize\n\t\t\tprimaryImage {\n\t\t\t\turl\n\t\t\t\talt\n\t\t\t}\n\t\t}\n\t}\n": types.LineItemSummaryFragmentFragmentDoc,
    "\n\tfragment Price on Money {\n\t\tcentAmount\n\t\tcurrency\n\t}\n": types.PriceFragmentDoc,
    "\n\tfragment PriceTag on Money {\n\t\tcentAmount\n\t\tcurrency\n\t}\n": types.PriceTagFragmentDoc,
    "\n\tfragment ProductCard on Product {\n\t\tname\n\t\tslug\n\t\tneckline\n\t\trawMaterial\n\t\tbrand\n\t\tdenier\n\t\tfit\n\t\tpackSize\n\n\t\tribbon {\n\t\t\tkind\n\t\t\tlabel\n\t\t}\n\n\t\timage {\n\t\t\turl\n\t\t\talt\n\t\t}\n\n\t\tstartingPrice {\n\t\t\tgross {\n\t\t\t\t...PriceTag\n\t\t\t}\n\t\t}\n\n\t\tprimaryVariant {\n\t\t\t...ProductVariantAnalyticsFragment\n\t\t\tid\n\t\t\tsku\n\t\t\tavailability\n\t\t\tprice {\n\t\t\t\tnet {\n\t\t\t\t\t...PriceTag\n\t\t\t\t}\n\t\t\t\tgross {\n\t\t\t\t\t...PriceTag\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\n\t\tprimaryCategory {\n\t\t\t...CategoryAnalyticsFragment\n\t\t}\n\n\t\tvariants {\n\t\t\t...ProductVariantAnalyticsFragment\n\t\t}\n\n\t\t...ProductAnalyticsFragment\n\t}\n": types.ProductCardFragmentDoc,
    "\n\tfragment ProductListingConfig on ProductListingConfig {\n\t\tcategoryId\n\t\tcategoryPageId\n\t\tdefaultOrder\n\t\tenabledFacets\n\t}\n": types.ProductListingConfigFragmentDoc,
    "\n\tfragment ProductSearchResults on ProductSearchResults {\n\t\ttotal\n\t\ttotalPages\n\t\tresults {\n\t\t\t...ProductCard\n\t\t}\n\t\tfacets {\n\t\t\t...FacetFragment\n\t\t}\n\t}\n": types.ProductSearchResultsFragmentDoc,
    "\n\tfragment SectionHeaderFragment on SectionHeader {\n\t\t__typename\n\t\tid\n\t\ttitle\n\t\timagePosition\n\t\timageStyle\n\t\tbodyText {\n\t\t\t...RichTextFragment\n\t\t}\n\t\tbuttons {\n\t\t\t...ButtonFragment\n\t\t}\n\t\theaderImage: image {\n\t\t\t...ImageAsset\n\t\t}\n\t}\n": types.SectionHeaderFragmentFragmentDoc,
    "\n\tquery GetProductCount(\n\t\t$storeContext: StoreContextInput!\n\t\t$sort: ProductSortOrder!\n\t\t$filters: [FacetFilterInput!]\n\t\t$searchTerm: String\n\t\t$categoryPageId: String\n\t) {\n\t\tproductSearch(\n\t\t\tstoreContext: $storeContext\n\t\t\tsort: $sort\n\t\t\tfilters: $filters\n\t\t\tcategoryPageId: $categoryPageId\n\t\t\tsearchTerm: $searchTerm\n\t\t) {\n\t\t\ttotal\n\t\t}\n\t}\n": types.GetProductCountDocument,
    "\n\tfragment TeaserRowFragment on TeaserRow {\n\t\t__typename\n\t\tid\n\t\ttitle\n\t\tviewMoreLink {\n\t\t\turl\n\t\t\tnewTab\n\t\t}\n\t\titems {\n\t\t\t__typename\n\t\t\tid\n\t\t\tcontent\n\t\t\timage {\n\t\t\t\t...ImageAsset\n\t\t\t}\n\t\t\tlink {\n\t\t\t\turl\n\t\t\t}\n\t\t\ttitle\n\t\t}\n\t}\n": types.TeaserRowFragmentFragmentDoc,
    "\n\tfragment UspsFragment on Usps {\n\t\tid\n\t\tusp1\n\t\tusp1Link {\n\t\t\turl\n\t\t\tnewTab\n\t\t\thash\n\t\t}\n\t\tusp2\n\t\tusp2Link {\n\t\t\turl\n\t\t\tnewTab\n\t\t\thash\n\t\t}\n\t\tusp3\n\t\tusp3Link {\n\t\t\turl\n\t\t\tnewTab\n\t\t\thash\n\t\t}\n\t}\n": types.UspsFragmentFragmentDoc,
    "\n\tfragment ArticleOverviewFragment on ArticleOverview {\n\t\t__typename\n\t\tid\n\t\tarticleItems: items(page: $currentPage) {\n\t\t\ttotalPages\n\t\t\tresults {\n\t\t\t\t...ArticleCardFragment\n\t\t\t}\n\t\t}\n\t}\n": types.ArticleOverviewFragmentFragmentDoc,
    "\n\tfragment BlockQuoteFragment on BlockQuote {\n\t\t__typename\n\t\tid\n\t\tcontent\n\t\tname\n\t\ttitle\n\t}\n": types.BlockQuoteFragmentFragmentDoc,
    "\n\tfragment ButtonFragment on Button {\n\t\t__typename\n\t\tid\n\t\ttitle\n\t\tvariant\n\t\tsize\n\t\tlink {\n\t\t\turl\n\t\t\tnewTab\n\t\t\thash\n\t\t}\n\t}\n": types.ButtonFragmentFragmentDoc,
    "\n\tfragment ButtonsFragment on Buttons {\n\t\t__typename\n\t\tid\n\t\tbuttons {\n\t\t\t...ButtonFragment\n\t\t}\n\t}\n": types.ButtonsFragmentFragmentDoc,
    "\n\tfragment CategorySectionFragment on CategorySection {\n\t\t__typename\n\t\tid\n\t\ttitle\n\t\tcategory\n\t\tsupportingText\n\t\theaderAlign\n\t\tvariant\n\t\tlink {\n\t\t\turl\n\t\t\tnewTab\n\t\t\thash\n\t\t}\n\t\titems {\n\t\t\t...CategoryCardFragment\n\t\t}\n\t}\n": types.CategorySectionFragmentFragmentDoc,
    "\n\tfragment ColumnBlockFragment on ColumnBlock {\n\t\t...DividerFragment\n\t\t...IframeFragment\n\t\t...ImageFragment\n\t\t...RichTextFragment\n\t\t...ButtonsFragment\n\t\t...BlockQuoteFragment\n\t\t...TableFragment\n\t\t...NewsletterCTAFragment\n\t\t...CookieDeclarationFragment\n\t\t...VideoFragment\n\t\t...FAQItemListFragment\n\t}\n": types.ColumnBlockFragmentFragmentDoc,
    "\n\tfragment FourColumnFragment on FourColumns {\n\t\t__typename\n\t\tid\n\t\talignment\n\t\tcolumn_1 {\n\t\t\t...ColumnBlockFragment\n\t\t}\n\t\tcolumn_2 {\n\t\t\t...ColumnBlockFragment\n\t\t}\n\t\tcolumn_3 {\n\t\t\t...ColumnBlockFragment\n\t\t}\n\t\tcolumn_4 {\n\t\t\t...ColumnBlockFragment\n\t\t}\n\t}\n": types.FourColumnFragmentFragmentDoc,
    "\n\tfragment ThreeColumnFragment on ThreeColumns {\n\t\t__typename\n\t\tid\n\t\talignment\n\t\tcolumn_1 {\n\t\t\t...ColumnBlockFragment\n\t\t}\n\t\tcolumn_2 {\n\t\t\t...ColumnBlockFragment\n\t\t}\n\t\tcolumn_3 {\n\t\t\t...ColumnBlockFragment\n\t\t}\n\t}\n": types.ThreeColumnFragmentFragmentDoc,
    "\n\tfragment TwoColumnFragment on TwoColumns {\n\t\t__typename\n\t\tid\n\t\talignment\n\t\tratio\n\t\tcolumn_1 {\n\t\t\t...ColumnBlockFragment\n\t\t}\n\t\tcolumn_2 {\n\t\t\t...ColumnBlockFragment\n\t\t}\n\t}\n": types.TwoColumnFragmentFragmentDoc,
    "\n\tfragment CookieDeclarationFragment on CookieDeclaration {\n\t\t__typename\n\t\tid\n\t}\n": types.CookieDeclarationFragmentFragmentDoc,
    "\n\tfragment CountrySpecificContainerFragment on CountrySpecificContainer {\n\t\t__typename\n\t\tid\n\t\tcountries\n\t\tcountrySpecificContainerBody: body {\n\t\t\t...BaseBlocksFragment\n\t\t}\n\t}\n": types.CountrySpecificContainerFragmentFragmentDoc,
    "\n\tfragment DividerFragment on Divider {\n\t\t__typename\n\t\tid\n\t}\n": types.DividerFragmentFragmentDoc,
    "\n\tfragment FAQItemListFragment on FAQItemList {\n\t\t__typename\n\t\tid\n\t\tfaqItems {\n\t\t\t...FAQItemFragment\n\t\t}\n\t}\n": types.FaqItemListFragmentFragmentDoc,
    "\n\tfragment IframeFragment on Iframe {\n\t\t__typename\n\t\tid\n\t\turl\n\t\theight\n\t}\n": types.IframeFragmentFragmentDoc,
    "\n\tfragment ImageFragment on Image {\n\t\t__typename\n\t\tid\n\t\timage {\n\t\t\t...ImageAsset\n\t\t}\n\t\taspectRatio\n\t}\n": types.ImageFragmentFragmentDoc,
    "\n\tfragment NewsletterCTAFragment on NewsletterCTA {\n\t\t__typename\n\t\tid\n\t\torientation\n\t}\n": types.NewsletterCtaFragmentFragmentDoc,
    "\n\tfragment RichTextFragment on RichText {\n\t\t__typename\n\t\tid\n\t\tcontent\n\t}\n": types.RichTextFragmentFragmentDoc,
    "\n\tfragment SectionFragment on Section {\n\t\t__typename\n\t\tid\n\t\tbackgroundColor\n\t\tsize\n\t\tspacing\n\t\tbody {\n\t\t\t...TeaserRowFragment\n\t\t\t...RichTextFragment\n\t\t\t...TwoColumnFragment\n\t\t\t...ThreeColumnFragment\n\t\t\t...FourColumnFragment\n\t\t\t...SectionHeaderFragment\n\t\t\t...ImageFragment\n\t\t\t...DividerFragment\n\t\t\t...IframeFragment\n\t\t\t...ButtonsFragment\n\t\t\t...BlockQuoteFragment\n\t\t\t...TableFragment\n\t\t\t...NewsletterCTAFragment\n\t\t\t...UGCFlowboxFragment\n\t\t\t...CookieDeclarationFragment\n\t\t\t...VideoFragment\n\t\t\t...FAQItemListFragment\n\t\t\t...ShopSectionFragment\n\t\t\t...CategorySectionFragment\n\t\t}\n\t}\n": types.SectionFragmentFragmentDoc,
    "\n\tfragment ShopSectionFragment on ShopSection {\n\t\t__typename\n\t\tid\n\t\ttitle\n\t\tsubtitle\n\t\tcategory\n\t\tviewAllLink {\n\t\t\turl\n\t\t\tnewTab\n\t\t}\n\t\tviewAllLabel\n\t\tvariant\n\t\tproducts {\n\t\t\t...ProductCard\n\t\t}\n\t}\n": types.ShopSectionFragmentFragmentDoc,
    "\n\tfragment TableCellFragment on ContentTableCell {\n\t\tvalue\n\t}\n\n\tfragment TableFragment on Table {\n\t\t__typename\n\t\tid\n\t\tstyle\n\t\tdividers\n\t\twidth\n\t\ttable {\n\t\t\tthead {\n\t\t\t\t...TableCellFragment\n\t\t\t}\n\t\t\ttbody {\n\t\t\t\tbody {\n\t\t\t\t\t...TableCellFragment\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n": types.TableCellFragmentFragmentDoc,
    "\n\tfragment UGCFlowboxFragment on UGCFlowbox {\n\t\t__typename\n\t\tid\n\t\tkey\n\t\ttags\n\t}\n": types.UgcFlowboxFragmentFragmentDoc,
    "\n\tfragment VideoFragment on Video {\n\t\t__typename\n\t\tid\n\t\tvideo\n\t}\n": types.VideoFragmentFragmentDoc,
    "\n\tfragment Cart on Cart {\n\t\t...CheckoutSummaryFragment\n\t\tid {\n\t\t\tid\n\t\t\tversion\n\t\t}\n\t\tlineItems {\n\t\t\t...LineItem\n\t\t}\n\t\tsubTotal {\n\t\t\t...Price\n\t\t}\n\t\tdiscount {\n\t\t\tamount {\n\t\t\t\t...Price\n\t\t\t}\n\t\t}\n\t\ttotal {\n\t\t\t...Price\n\t\t}\n\t\tsubTotalBeforeDiscount {\n\t\t\t...Price\n\t\t}\n\t\tshippingCosts {\n\t\t\t...Price\n\t\t}\n\t\tshippingOption\n\t}\n": types.CartFragmentDoc,
    "\n\tfragment LineItem on LineItem {\n\t\tid\n\t\tquantity\n\t\tproductId\n\t\tproductSlug\n\t\tproductName\n\t\taddedAt\n\t\tavailableStock\n\t\tneckline\n\t\trawMaterial\n\t\tbrand\n\t\tdenier\n\t\tfit\n\t\tpackSize\n\t\tcategory {\n\t\t\tancestors {\n\t\t\t\tstoryblokName\n\t\t\t\tname\n\t\t\t}\n\t\t}\n\t\tregularPrice {\n\t\t\tgross {\n\t\t\t\t...Price\n\t\t\t}\n\t\t}\n\t\tprice {\n\t\t\tgross {\n\t\t\t\t...Price\n\t\t\t}\n\t\t\ttax {\n\t\t\t\tvalue {\n\t\t\t\t\t...Price\n\t\t\t\t}\n\t\t\t\trate\n\t\t\t}\n\t\t}\n\t\tdiscounts {\n\t\t\tcode\n\t\t\tvalue {\n\t\t\t\tcentAmount\n\t\t\t\tcurrency\n\t\t\t}\n\t\t\tdescription\n\t\t}\n\t\tvariant {\n\t\t\tname\n\t\t\tdescriptionShort\n\t\t\tsku\n\t\t\tsize\n\t\t\tcolor\n\t\t\tprimaryImage {\n\t\t\t\turl\n\t\t\t\talt\n\t\t\t}\n\t\t\torderConfiguration {\n\t\t\t\tmin\n\t\t\t\tmax\n\t\t\t\tstepSize\n\t\t\t}\n\t\t}\n\t}\n": types.LineItemFragmentDoc,
    "\n\tmutation CartLineItemsAdd(\n\t\t$storeContext: StoreContextInput!\n\t\t$id: CartIdentifierInput\n\t\t$lineItems: [CartLineItemCreateInput!]!\n\t) {\n\t\tcartLineItemsAdd(storeContext: $storeContext, id: $id, lineItems: $lineItems) {\n\t\t\t...Cart\n\t\t}\n\t}\n": types.CartLineItemsAddDocument,
    "\n\tquery GetCart($storeContext: StoreContextInput!) {\n\t\tcart(storeContext: $storeContext) {\n\t\t\t...Cart\n\t\t}\n\t}\n": types.GetCartDocument,
    "\n\tmutation CartLineItemsUpdate(\n\t\t$storeContext: StoreContextInput!\n\t\t$id: CartIdentifierInput!\n\t\t$lineItems: [CartLineItemUpdateInput!]!\n\t) {\n\t\tcartLineItemsUpdate(storeContext: $storeContext, id: $id, lineItems: $lineItems) {\n\t\t\t...Cart\n\t\t}\n\t}\n": types.CartLineItemsUpdateDocument,
    "\n\tmutation ProcessPaazlCheckout($storeContext: StoreContextInput!) {\n\t\tcheckoutUpdate(storeContext: $storeContext, processPaazlCheckout: true) {\n\t\t\t...Cart\n\t\t}\n\t}\n": types.ProcessPaazlCheckoutDocument,
    "\n\tfragment Address on Address {\n\t\tid\n\t\temail\n\t\tsalutation\n\t\tfirstName\n\t\tlastName\n\t\tstreetName\n\t\thouseNumber\n\t\thouseNumberSuffix\n\t\tpostalCode\n\t\tcity\n\t\tphone\n\t\tcountry\n\t\tcompany\n\t}\n": types.AddressFragmentDoc,
    "\n\tquery GetCustomer {\n\t\tcustomer {\n\t\t\t...Customer\n\t\t}\n\t}\n": types.GetCustomerDocument,
    "\n\tfragment Customer on Customer {\n\t\tfirstName\n\t\tlastName\n\t\taccountType\n\t\tcompany\n\t\temail\n\t\tvatNumber\n\t\tbillingAddress {\n\t\t\t...Address\n\t\t}\n\t\tshippingAddress {\n\t\t\t...Address\n\t\t}\n\t\taddresses {\n\t\t\t...Address\n\t\t}\n\t}\n": types.CustomerFragmentDoc,
    "\n\tmutation CustomerLogin($storeContext: StoreContextInput!, $email: String!, $password: String!) {\n\t\tcustomerLogin(storeContext: $storeContext, email: $email, password: $password) {\n\t\t\t...Customer\n\t\t}\n\t}\n": types.CustomerLoginDocument,
    "\n\tmutation CustomerLogout($storeContext: StoreContextInput!) {\n\t\tcustomerLogout(storeContext: $storeContext)\n\t}\n": types.CustomerLogoutDocument,
    "\n\tfragment AddressValidationResult on AddressValidationResult {\n\t\tvalid\n\t\tsuggestions {\n\t\t\tcity\n\t\t\tcountry\n\t\t\thouseNumber\n\t\t\thouseNumberSuffix\n\t\t\tpostalCode\n\t\t\tstreetName\n\t\t}\n\t}\n": types.AddressValidationResultFragmentDoc,
    "\n\tquery GetAddressValidate($storeContext: StoreContextInput!, $address: AddressInput!) {\n\t\taddressValidate(storeContext: $storeContext, address: $address) {\n\t\t\t...AddressValidationResult\n\t\t}\n\t}\n": types.GetAddressValidateDocument,
    "\n\tquery GetProducts(\n\t\t$storeContext: StoreContextInput!\n\t\t$sort: ProductSortOrder!\n\t\t$filters: [FacetFilterInput!]\n\t\t$searchTerm: String\n\t\t$categoryPageId: String\n\t\t$pageSize: Int!\n\t\t$page: Int!\n\t) {\n\t\tproductSearch(\n\t\t\tstoreContext: $storeContext\n\t\t\tsort: $sort\n\t\t\tfilters: $filters\n\t\t\tcategoryPageId: $categoryPageId\n\t\t\tsearchTerm: $searchTerm\n\t\t\tpageSize: $pageSize\n\t\t\tpage: $page\n\t\t) {\n\t\t\t...ProductSearchResults\n\t\t}\n\t}\n": types.GetProductsDocument,
    "\n\tfragment ProductVariantAnalyticsFragment on ProductVariant {\n\t\tname\n\t\tsku\n\t\tregularPrice {\n\t\t\tgross {\n\t\t\t\t...Price\n\t\t\t}\n\t\t}\n\t\tattributes {\n\t\t\tname\n\t\t\tvalue\n\t\t}\n\t}\n\n\tfragment CategoryAnalyticsFragment on ProductCategory {\n\t\tname\n\t\tid\n\t\tancestors {\n\t\t\tstoryblokName\n\t\t\tname\n\t\t}\n\t}\n\n\tfragment ProductAnalyticsFragment on Product {\n\t\tid\n\t\tbrand\n\t\tname\n\t}\n\n\tfragment CombinedProductAnalyticsFragment on Product {\n\t\t...ProductAnalyticsFragment\n\n\t\tprimaryCategory {\n\t\t\t...CategoryAnalyticsFragment\n\t\t}\n\n\t\tprimaryVariant {\n\t\t\t...ProductVariantAnalyticsFragment\n\t\t}\n\n\t\tvariants {\n\t\t\t...ProductVariantAnalyticsFragment\n\t\t}\n\t}\n": types.ProductVariantAnalyticsFragmentFragmentDoc,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tmutation StoreContentPreviewData($path: String!, $data: String!) {\n\t\tstoreContentPreview(data: $data, path: $path) {\n\t\t\tid\n\t\t}\n\t}\n"): typeof import('./graphql').StoreContentPreviewDataDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment FooterNewsletterFragment on Site {\n\t\tshowNewsletterCTA\n\t\tnewsletterCTATitle\n\t\tnewsletterCTABody\n\t}\n"): typeof import('./graphql').FooterNewsletterFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tmutation NewsletterSignUp($storeContext: StoreContextInput!, $email: String!) {\n\t\tnewsletterSignUp(storeContext: $storeContext, email: $email)\n\t}\n"): typeof import('./graphql').NewsletterSignUpDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery GetCompanyReview($storeContext: StoreContextInput!) {\n\t\tcompanyReview(storeContext: $storeContext) {\n\t\t\tamount\n\t\t\ttotalAmount\n\t\t\taverageRating\n\t\t}\n\t}\n"): typeof import('./graphql').GetCompanyReviewDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery GetSite($storeContext: StoreContextInput!, $version: ContentVersion!, $previewID: String) {\n\t\tsite(storeContext: $storeContext, version: $version, previewID: $previewID) {\n\t\t\t...Site\n\t\t}\n\t}\n"): typeof import('./graphql').GetSiteDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment Site on Site {\n\t\t__typename\n\t\tid\n\n\t\tlogo {\n\t\t\t...ImageAsset\n\t\t}\n\n\t\torganizationAddress {\n\t\t\tpostalCode\n\t\t\tlocality\n\t\t\tcountry\n\t\t\tstreet\n\t\t}\n\n\t\tfooterLogo {\n\t\t\t...ImageAsset\n\t\t}\n\n\t\tmainMenu {\n\t\t\t...MainMenu\n\t\t\t...MenuLink\n\t\t}\n\n\t\tfooterMenu {\n\t\t\t...MenuBlock\n\t\t}\n\n\t\tpaymentMethods {\n\t\t\t...ImageAsset\n\t\t}\n\n\t\tcustomerServiceEmail\n\t\tcustomerServiceHours\n\t\tcustomerServicePhone\n\t\tfaqItems {\n\t\t\t...FAQItemFragment\n\t\t}\n\n\t\ttrustMarks {\n\t\t\t...ImageAsset\n\t\t}\n\n\t\tcarriers {\n\t\t\t...ImageAsset\n\t\t}\n\n\t\tpaymentTerms {\n\t\t\t...RichTextFragment\n\t\t}\n\n\t\tsocialsHeading\n\t\tfacebook {\n\t\t\turl\n\t\t}\n\t\tinstagram {\n\t\t\turl\n\t\t}\n\t\tlinkedIn {\n\t\t\turl\n\t\t}\n\t\tyoutube {\n\t\t\turl\n\t\t}\n\t\ttiktok {\n\t\t\turl\n\t\t}\n\t\tpinterest {\n\t\t\turl\n\t\t}\n\n\t\tbusinessLabel\n\t\tbusinessPage {\n\t\t\turl\n\t\t\tnewTab\n\t\t}\n\t\tserviceLabel\n\t\tservicePage {\n\t\t\turl\n\t\t\tnewTab\n\t\t}\n\t\tdisclaimerLabel\n\t\tdisclaimerPage {\n\t\t\turl\n\t\t\tnewTab\n\t\t}\n\t\ttermsLabel\n\t\ttermsPage {\n\t\t\turl\n\t\t\tnewTab\n\t\t}\n\t\tcookiesLabel\n\t\tcookiesPage {\n\t\t\turl\n\t\t\tnewTab\n\t\t}\n\t\tprivacyLabel\n\t\tprivacyPage {\n\t\t\turl\n\t\t\tnewTab\n\t\t}\n\t\tusps {\n\t\t\t...UspsFragment\n\t\t}\n\n\t\tnotificationBanners {\n\t\t\t...NotificationBanner\n\t\t}\n\n\t\t...FooterNewsletterFragment\n\t}\n"): typeof import('./graphql').SiteFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment MainMenu on MenuItem {\n\t\t...MenuItemFields\n\t\tsubMenuItems {\n\t\t\t...MenuItemFields\n\t\t\t...MenuLink\n\t\t\t... on MenuItem {\n\t\t\t\tsubMenuItems {\n\t\t\t\t\t...MenuItemFields\n\t\t\t\t\t...MenuLink\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"): typeof import('./graphql').MainMenuFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment MenuItemFields on MenuItem {\n\t\t__typename\n\t\tid\n\t\tlabel\n\t\toptionalLink: link {\n\t\t\turl\n\t\t}\n\t}\n"): typeof import('./graphql').MenuItemFieldsFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment MenuLink on MenuLink {\n\t\t__typename\n\t\tid\n\t\tlabel\n\t\tlink {\n\t\t\turl\n\t\t\tnewTab\n\t\t}\n\t}\n"): typeof import('./graphql').MenuLinkFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment MenuBlock on MenuBlock {\n\t\t__typename\n\t\tid\n\t\tlabel\n\t\tmenuLinks {\n\t\t\t...MenuLink\n\t\t}\n\t}\n"): typeof import('./graphql').MenuBlockFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment NotificationBanner on NotificationBanner {\n\t\t__typename\n\t\tid\n\t\ttext\n\t\tlinkLabel\n\t\toptionalLink: link {\n\t\t\turl\n\t\t}\n\t}\n"): typeof import('./graphql').NotificationBannerFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tmutation CustomerPasswordReset(\n\t\t$storeContext: StoreContextInput!\n\t\t$password: String!\n\t\t$token: String!\n\t) {\n\t\tcustomerPasswordReset(storeContext: $storeContext, password: $password, token: $token) {\n\t\t\t...Customer\n\t\t}\n\t}\n"): typeof import('./graphql').CustomerPasswordResetDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tmutation CustomerPasswordResetRequest($storeContext: StoreContextInput!, $email: String!) {\n\t\tcustomerPasswordResetRequest(storeContext: $storeContext, email: $email)\n\t}\n"): typeof import('./graphql').CustomerPasswordResetRequestDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tmutation CustomerCreate($storeContext: StoreContextInput!, $customer: CustomerCreateInput!) {\n\t\tcustomerCreate(storeContext: $storeContext, customer: $customer) {\n\t\t\t...Customer\n\t\t}\n\t}\n"): typeof import('./graphql').CustomerCreateDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tmutation CustomerPasswordUpdate(\n\t\t$storeContext: StoreContextInput!\n\t\t$password: String!\n\t\t$newPassword: String!\n\t) {\n\t\tcustomerPasswordUpdate(\n\t\t\tstoreContext: $storeContext\n\t\t\tpassword: $password\n\t\t\tnewPassword: $newPassword\n\t\t) {\n\t\t\t...Customer\n\t\t}\n\t}\n"): typeof import('./graphql').CustomerPasswordUpdateDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment AbstractPageFragment on AbstractPage {\n\t\t__typename\n\t\tid\n\t\tname\n\t\tpath\n\t\ttranslatedPaths {\n\t\t\tlocale\n\t\t\tpath\n\t\t}\n\t\tbreadcrumbs {\n\t\t\t...BreadcrumbFragment\n\t\t}\n\t\tseo {\n\t\t\t...ContentSEOFragment\n\t\t}\n\t}\n"): typeof import('./graphql').AbstractPageFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment ContentSEOFragment on ContentSEO {\n\t\t__typename\n\t\ttitle\n\t\tdescription\n\t\togTitle\n\t\togDescription\n\t\togImage\n\t\ttwitterTitle\n\t\ttwitterDescription\n\t\ttwitterImage\n\t}\n"): typeof import('./graphql').ContentSeoFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment ContentPageFragment on ContentPage {\n\t\t...AbstractPageFragment\n\t\tcontentPageTitle: title\n\t\tdescription\n\n\t\thero {\n\t\t\t...HeroFragment\n\t\t}\n\t\tusps {\n\t\t\t...UspsFragment\n\t\t}\n\t\tbody {\n\t\t\t...BlocksFragment\n\t\t}\n\t}\n"): typeof import('./graphql').ContentPageFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment FAQPageFragment on FAQPage {\n\t\t...AbstractPageFragment\n\n\t\ttitle\n\n\t\tfaqGroups {\n\t\t\t...FAQGroupFragment\n\n\t\t\ttranslatedPaths {\n\t\t\t\tlocale\n\t\t\t\tpath\n\t\t\t}\n\t\t}\n\t}\n"): typeof import('./graphql').FaqPageFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment BaseBlocksFragment on Block {\n\t\t...IframeFragment\n\t\t...SectionFragment\n\t\t...TeaserRowFragment\n\t\t...RichTextFragment\n\t\t...TwoColumnFragment\n\t\t...ThreeColumnFragment\n\t\t...FourColumnFragment\n\t\t...SectionHeaderFragment\n\t\t...DividerFragment\n\t\t...ImageFragment\n\t\t...ButtonsFragment\n\t\t...BlockQuoteFragment\n\t\t...CategorySectionFragment\n\t\t...TableFragment\n\t\t...NewsletterCTAFragment\n\t\t...UGCFlowboxFragment\n\t\t...ShopSectionFragment\n\t\t...CookieDeclarationFragment\n\t\t...ArticleOverviewFragment\n\t\t...VideoFragment\n\t\t...FAQItemListFragment\n\t}\n"): typeof import('./graphql').BaseBlocksFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment BlocksFragment on Block {\n\t\t...BaseBlocksFragment\n\t\t...CountrySpecificContainerFragment\n\t}\n"): typeof import('./graphql').BlocksFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment ProductListingPageFragment on ProductListingPage {\n\t\t...AbstractPageFragment\n\t\tplpTitle: title\n\t\tdescription\n\t\thero {\n\t\t\t...HeroFragment\n\t\t}\n\t\tafterBlocks {\n\t\t\t...BlocksFragment\n\t\t}\n\t\tbeforeBlocks {\n\t\t\t...BlocksFragment\n\t\t}\n\t\tcategory {\n\t\t\tid\n\t\t}\n\t\tproductListingConfig {\n\t\t\t...ProductListingConfig\n\t\t}\n\t}\n"): typeof import('./graphql').ProductListingPageFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment ArticlePageFragment on ArticlePage {\n\t\t...AbstractPageFragment\n\n\t\ttitle\n\t\timage {\n\t\t\tfilename\n\t\t\talt\n\t\t}\n\t\tlocation\n\t\tpublishedAt\n\t\tbody {\n\t\t\t...TwoColumnFragment\n\t\t\t...ThreeColumnFragment\n\t\t\t...FourColumnFragment\n\t\t\t...BlockQuoteFragment\n\t\t\t...ButtonsFragment\n\t\t\t...ImageFragment\n\t\t\t...RichTextFragment\n\t\t\t...TableFragment\n\t\t\t...VideoFragment\n\t\t\t...FAQItemListFragment\n\t\t}\n\t}\n"): typeof import('./graphql').ArticlePageFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery PageRedirect(\n\t\t$path: String!\n\t\t$storeContext: StoreContextInput!\n\t\t$pageType: PageRedirectPageType!\n\t) {\n\t\tpageRedirect(path: $path, storeContext: $storeContext, pageType: $pageType) {\n\t\t\tdestination\n\t\t\tstatusCode\n\t\t}\n\t}\n"): typeof import('./graphql').PageRedirectDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery GetContentPage(\n\t\t$storeContext: StoreContextInput!\n\t\t$path: String!\n\t\t$previewID: String\n\t\t$version: ContentVersion\n\t\t$currentPage: Int\n\t) {\n\t\tpage(\n\t\t\tstoreContext: $storeContext\n\t\t\tpath: $path\n\t\t\tpreviewID: $previewID\n\t\t\tversion: $version\n\t\t\tcurrentPage: $currentPage\n\t\t) {\n\t\t\t...ContentPageFragment\n\t\t\t...ProductListingPageFragment\n\t\t\t...ArticlePageFragment\n\t\t\t...FAQPageFragment\n\t\t}\n\t}\n"): typeof import('./graphql').GetContentPageDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment FAQGroupFragment on FAQGroup {\n\t\tid\n\t\ttitle\n\t\tslug\n\n\t\ticon {\n\t\t\t...ImageAsset\n\t\t}\n\n\t\tleadText {\n\t\t\t...RichTextFragment\n\t\t}\n\n\t\tfaqItems {\n\t\t\t...FAQItemFragment\n\t\t}\n\t}\n"): typeof import('./graphql').FaqGroupFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment ProductBuySectionFragment on ProductVariant {\n\t\tsku\n\t\tavailability\n\t\tavailabilityQuantity\n\t\tprice {\n\t\t\tgross {\n\t\t\t\t...Price\n\t\t\t}\n\t\t}\n\t\tregularPrice {\n\t\t\tgross {\n\t\t\t\t...Price\n\t\t\t}\n\t\t}\n\t}\n"): typeof import('./graphql').ProductBuySectionFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment ProductDetailPageFragment on Product {\n\t\tmeta {\n\t\t\ttitle\n\t\t\tdescription\n\t\t}\n\n\t\tusps {\n\t\t\t...UspsFragment\n\t\t}\n\n\t\tname\n\t\tslug\n\t\tavailability\n\t\tneckline\n\t\trawMaterial\n\t\tbrand\n\t\tdenier\n\t\tfit\n\t\tpackSize\n\n\t\tbrand\n\t\tribbon {\n\t\t\tkind\n\t\t\tlabel\n\t\t}\n\n\t\tprimaryVariant {\n\t\t\tsku\n\t\t\timages {\n\t\t\t\turl\n\t\t\t\talt\n\t\t\t}\n\t\t\t...ProductVariantAnalyticsFragment\n\t\t}\n\n\t\tprimaryCategory {\n\t\t\tid\n\t\t\tname\n\t\t\tslug\n\t\t\tancestors {\n\t\t\t\tstoryblokName\n\t\t\t\tname\n\t\t\t\tid\n\t\t\t}\n\t\t}\n\n\t\tvariants {\n\t\t\t...ProductVariantAnalyticsFragment\n\t\t\t...ProductVariantFragment\n\t\t\t...ProductBuySectionFragment\n\t\t}\n\n\t\trelatedProducts {\n\t\t\tid\n\t\t\tslug\n\t\t\tavailability\n\t\t\timage {\n\t\t\t\turl\n\t\t\t\talt\n\t\t\t}\n\t\t\tprimaryVariant {\n\t\t\t\tsku\n\t\t\t}\n\t\t\tvariants {\n\t\t\t\tavailability\n\t\t\t\tprice {\n\t\t\t\t\tgross {\n\t\t\t\t\t\tcentAmount\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t\tstartingPrice {\n\t\t\t\tgross {\n\t\t\t\t\t...Price\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\n\t\tstartingPrice {\n\t\t\tgross {\n\t\t\t\t...Price\n\t\t\t}\n\t\t}\n\n\t\tinstructions {\n\t\t\tkey\n\t\t\tlabel\n\t\t}\n\n\t\tmaterials {\n\t\t\tbase\n\t\t\toutside\n\t\t\tfilling\n\t\t\tlining\n\t\t}\n\n\t\treviews {\n\t\t\tamount\n\t\t\taverageRating\n\t\t\tlowestRating\n\t\t\thighestRating\n\t\t}\n\n\t\tsizeGuide {\n\t\t\t...TableFragment\n\t\t\t...ImageFragment\n\t\t\t...RichTextFragment\n\t\t\t...CountrySpecificContainerFragment\n\t\t}\n\n\t\tbreadcrumbs {\n\t\t\t...BreadcrumbFragment\n\t\t}\n\n\t\t...ProductAnalyticsFragment\n\t}\n"): typeof import('./graphql').ProductDetailPageFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment ProductVariantFragment on ProductVariant {\n\t\tid\n\t\tname\n\t\tsku\n\t\tavailability\n\t\timages {\n\t\t\turl\n\t\t\talt\n\t\t}\n\n\t\t# only needed for the main product\n\t\tdeliveryTime\n\t\tdescription\n\t\tattributes {\n\t\t\tname\n\t\t\tlabel\n\t\t\tvalue\n\t\t}\n\t\torderConfiguration {\n\t\t\tmin\n\t\t\tmax\n\t\t\tstepSize\n\t\t}\n\t}\n"): typeof import('./graphql').ProductVariantFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery GetProductDetailPage($storeContext: StoreContextInput!, $slug: String!, $currentPage: Int) {\n\t\tproduct(storeContext: $storeContext, slug: $slug) {\n\t\t\t...ProductDetailPageFragment\n\n\t\t\tlocalizedSlugs {\n\t\t\t\tlocale\n\t\t\t\tslug\n\t\t\t}\n\t\t}\n\t}\n"): typeof import('./graphql').GetProductDetailPageDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment StoreFragment on Store {\n\t\tslug\n\t\taddress {\n\t\t\taddressLine\n\t\t\tcity\n\t\t\tdistrict\n\t\t\tphone\n\t\t\tzipcode\n\t\t\tstreetNumber\n\t\t\tcountryId\n\t\t}\n\t\tname\n\t\tslug\n\t\tstoreCode\n\t\topeningHours {\n\t\t\tfridayclose1\n\t\t\tfridayclose2\n\t\t\tfridayopen1\n\t\t\tfridayopen2\n\t\t\tmondayclose1\n\t\t\tmondayclose2\n\t\t\tmondayopen1\n\t\t\tmondayopen2\n\t\t\tsaturdayclose1\n\t\t\tsaturdayclose2\n\t\t\tsaturdayopen1\n\t\t\tsaturdayopen2\n\t\t\tsundayclose1\n\t\t\tsundayclose2\n\t\t\tsundayopen1\n\t\t\tsundayopen2\n\t\t\tthursdayclose1\n\t\t\tthursdayclose2\n\t\t\tthursdayopen1\n\t\t\tthursdayopen2\n\t\t\ttuesdayclose1\n\t\t\ttuesdayclose2\n\t\t\ttuesdayopen1\n\t\t\ttuesdayopen2\n\t\t\twednesdayclose1\n\t\t\twednesdayclose2\n\t\t\twednesdayopen1\n\t\t\twednesdayopen2\n\t\t}\n\t\tcoordinates {\n\t\t\tlat\n\t\t\tlng\n\t\t}\n\t\tnearbyStores {\n\t\t\tname\n\t\t\tslug\n\t\t\taddress {\n\t\t\t\taddressLine\n\t\t\t\tcity\n\t\t\t\tstreetNumber\n\t\t\t\tphone\n\t\t\t\tzipcode\n\t\t\t}\n\t\t\tdistanceInMeters\n\t\t\tobjectID\n\t\t\tstoreCode\n\t\t}\n\t}\n"): typeof import('./graphql').StoreFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery GetStore($slug: String!) {\n\t\tstore(slug: $slug) {\n\t\t\t...StoreFragment\n\t\t}\n\t}\n"): typeof import('./graphql').GetStoreDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment CheckoutSummaryFragment on Cart {\n\t\t...CartSummaryFragment\n\n\t\tlineItems {\n\t\t\t...LineItem\n\n\t\t\tid\n\t\t\tquantity\n\t\t\tproductSlug\n\t\t\tproductName\n\t\t\taddedAt\n\t\t\tvariant {\n\t\t\t\tname\n\t\t\t\tsku\n\t\t\t\tprimaryImage {\n\t\t\t\t\turl\n\t\t\t\t\talt\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"): typeof import('./graphql').CheckoutSummaryFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment OrderDetail on Order {\n\t\tcustomerEmail\n\t\torderNumber\n\t\tshippingAddress {\n\t\t\tfirstName\n\t\t\tlastName\n\t\t\tstreetName\n\t\t\thouseNumber\n\t\t\thouseNumberSuffix\n\t\t\tpostalCode\n\t\t\tcity\n\t\t\tphone\n\t\t}\n\t\tshippingCosts {\n\t\t\t...Price\n\t\t}\n\t\testimatedMaxDeliveryDays\n\t\tlineItems {\n\t\t\tid\n\t\t\tquantity\n\t\t\tproductId\n\t\t\tproductSlug\n\t\t\tproductName\n\t\t\tcategory {\n\t\t\t\tancestors {\n\t\t\t\t\tstoryblokName\n\t\t\t\t\tname\n\t\t\t\t}\n\t\t\t}\n\t\t\tprice {\n\t\t\t\tgross {\n\t\t\t\t\t...Price\n\t\t\t\t}\n\t\t\t}\n\t\t\tvariant {\n\t\t\t\tname\n\t\t\t\tsize\n\t\t\t\tcolor\n\t\t\t\tsku\n\t\t\t\tprimaryImage {\n\t\t\t\t\turl\n\t\t\t\t\talt\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t\tcurrency\n\t\ttotal {\n\t\t\tgross {\n\t\t\t\t...Price\n\t\t\t}\n\t\t}\n\t\tselectedPaymentMethod {\n\t\t\t...PaymentMethod\n\t\t}\n\t\tshippingMethod\n\t\tshippingOption\n\t}\n"): typeof import('./graphql').OrderDetailFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery Order($storeContext: StoreContextInput!, $orderId: String!) {\n\t\torderConfirmation(storeContext: $storeContext, orderId: $orderId) {\n\t\t\t...OrderDetail\n\t\t}\n\t}\n"): typeof import('./graphql').OrderDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery GetCheckoutDelivery($storeContext: StoreContextInput!) {\n\t\tcart(storeContext: $storeContext) {\n\t\t\tpaazlCheckoutConfig {\n\t\t\t\t...PaazlConfigFragment\n\t\t\t}\n\t\t}\n\t}\n"): typeof import('./graphql').GetCheckoutDeliveryDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment PaazlConfigFragment on PaazlCheckoutConfig {\n\t\tconfigObject\n\t}\n"): typeof import('./graphql').PaazlConfigFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment AddressFragment on Address {\n\t\tid\n\t\temail\n\t\tfirstName\n\t\tlastName\n\t\tstreetName\n\t\thouseNumber\n\t\thouseNumberSuffix\n\t\tpostalCode\n\t\tcity\n\t\tphone\n\t\tcountry\n\t\tcompany\n\t\tvatNumber\n\t}\n"): typeof import('./graphql').AddressFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tmutation CustomerAddressUpdate($storeContext: StoreContextInput!, $address: AddressUpdateInput!) {\n\t\tcustomerInformationUpdate(storeContext: $storeContext, address: $address) {\n\t\t\t...Customer\n\t\t}\n\t}\n"): typeof import('./graphql').CustomerAddressUpdateDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery GetCheckoutInformation($storeContext: StoreContextInput!) {\n\t\tcart(storeContext: $storeContext) {\n\t\t\tcustomerEmail\n\t\t\tbillingAddress {\n\t\t\t\t...AddressFragment\n\t\t\t}\n\t\t}\n\t}\n"): typeof import('./graphql').GetCheckoutInformationDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tmutation UpdateCheckoutInformation(\n\t\t$storeContext: StoreContextInput!\n\t\t$setBillingAddress: AddressInput\n\t\t$setShippingAddress: AddressInput\n\t\t$setCustomerEmail: String\n\t) {\n\t\tcheckoutUpdate(\n\t\t\tstoreContext: $storeContext\n\t\t\tsetBillingAddress: $setBillingAddress\n\t\t\tsetShippingAddress: $setShippingAddress\n\t\t\tsetCustomerEmail: $setCustomerEmail\n\t\t) {\n\t\t\tcustomerEmail\n\t\t\tshippingAddress {\n\t\t\t\t...AddressFragment\n\t\t\t}\n\t\t\tbillingAddress {\n\t\t\t\t...AddressFragment\n\t\t\t}\n\t\t}\n\t}\n"): typeof import('./graphql').UpdateCheckoutInformationDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tmutation UpdateCheckoutLogin(\n\t\t$storeContext: StoreContextInput!\n\t\t$id: CartIdentifierInput!\n\t\t$setCustomerEmail: String\n\t\t$setBillingAddress: AddressInput\n\t) {\n\t\tcheckoutUpdate(\n\t\t\tstoreContext: $storeContext\n\t\t\tid: $id\n\t\t\tsetCustomerEmail: $setCustomerEmail\n\t\t\tsetBillingAddress: $setBillingAddress\n\t\t) {\n\t\t\tid {\n\t\t\t\tid\n\t\t\t\tversion\n\t\t\t}\n\t\t}\n\t}\n"): typeof import('./graphql').UpdateCheckoutLoginDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tmutation CheckoutCustomerLogin(\n\t\t$storeContext: StoreContextInput!\n\t\t$email: String!\n\t\t$password: String!\n\t) {\n\t\tcustomerLogin(storeContext: $storeContext, email: $email, password: $password) {\n\t\t\t...Customer\n\t\t}\n\t}\n"): typeof import('./graphql').CheckoutCustomerLoginDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery GetCheckoutLogin($storeContext: StoreContextInput!) {\n\t\tcart(storeContext: $storeContext) {\n\t\t\tid {\n\t\t\t\tid\n\t\t\t\tversion\n\t\t\t}\n\n\t\t\tcustomerEmail\n\t\t}\n\t}\n"): typeof import('./graphql').GetCheckoutLoginDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment FailedOrderDetail on Order {\n\t\t...OrderDetail\n\n\t\tlineItems {\n\t\t\tid\n\t\t\tquantity\n\t\t\tproductId\n\t\t\tproductSlug\n\t\t\tproductName\n\t\t\taddedAt\n\t\t\tcategory {\n\t\t\t\tancestors {\n\t\t\t\t\tstoryblokName\n\t\t\t\t\tname\n\t\t\t\t}\n\t\t\t}\n\t\t\tprice {\n\t\t\t\tgross {\n\t\t\t\t\t...Price\n\t\t\t\t}\n\t\t\t}\n\t\t\tvariant {\n\t\t\t\tname\n\t\t\t\tsku\n\t\t\t\tprimaryImage {\n\t\t\t\t\turl\n\t\t\t\t\talt\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\n\t\tsubTotal {\n\t\t\tgross {\n\t\t\t\t...Price\n\t\t\t}\n\t\t}\n\n\t\ttotal {\n\t\t\tgross {\n\t\t\t\t...Price\n\t\t\t}\n\t\t}\n\n\t\tshippingCosts {\n\t\t\t...Price\n\t\t}\n\n\t\tselectedPaymentMethod {\n\t\t\t...PaymentMethod\n\t\t}\n\n\t\tshippingOption\n\t\tpaymentStatus\n\t}\n"): typeof import('./graphql').FailedOrderDetailFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery FailedOrder($storeContext: StoreContextInput!, $orderId: String!) {\n\t\torder(storeContext: $storeContext, orderId: $orderId) {\n\t\t\t...FailedOrderDetail\n\t\t}\n\n\t\tavailablePaymentMethods(storeContext: $storeContext, orderId: $orderId, device: web) {\n\t\t\tname\n\t\t\tpaymentMethod {\n\t\t\t\t...PaymentMethod\n\t\t\t}\n\t\t}\n\t}\n"): typeof import('./graphql').FailedOrderDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery GetOrderPaymentStatus($orderId: String!, $storeContext: StoreContextInput!) {\n\t\torderPaymentStatus(orderId: $orderId, storeContext: $storeContext)\n\t}\n"): typeof import('./graphql').GetOrderPaymentStatusDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tmutation PaymentCreate($storeContext: StoreContextInput!, $paymentContext: PaymentContextInput!) {\n\t\tcreatePayment(storeContext: $storeContext, paymentContext: $paymentContext) {\n\t\t\tredirectURL\n\t\t\tadditionalData\n\t\t\tmethod\n\t\t}\n\t}\n"): typeof import('./graphql').PaymentCreateDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery GetCheckoutPayment($storeContext: StoreContextInput!) {\n\t\tcart(storeContext: $storeContext) {\n\t\t\tavailablePaymentMethods(device: web) {\n\t\t\t\tname\n\t\t\t\tpaymentMethod {\n\t\t\t\t\t...PaymentMethod\n\t\t\t\t}\n\t\t\t}\n\t\t\tselectedPaymentMethod {\n\t\t\t\t...PaymentMethod\n\t\t\t}\n\t\t}\n\t}\n"): typeof import('./graphql').GetCheckoutPaymentDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment PaymentMethod on PaymentMethod {\n\t\tid\n\t\tname\n\t\tprovider\n\t\tissuers {\n\t\t\tid\n\t\t\tname\n\t\t}\n\t}\n"): typeof import('./graphql').PaymentMethodFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tmutation CreateOrder($storeContext: StoreContextInput!) {\n\t\tcheckoutComplete(storeContext: $storeContext) {\n\t\t\torderId\n\t\t\torderNumber\n\t\t}\n\t}\n"): typeof import('./graphql').CreateOrderDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery GetContentSlugs(\n\t\t$storeContext: StoreContextInput!\n\t\t$pageSize: Int!\n\t\t$page: Int!\n\t\t$includeCountryUnique: Boolean!\n\t\t$includeShared: Boolean!\n\t) {\n\t\tpages(\n\t\t\tstoreContext: $storeContext\n\t\t\tpageSize: $pageSize\n\t\t\tpage: $page\n\t\t\tversion: published\n\t\t\tincludeCountryUnique: $includeCountryUnique\n\t\t\tincludeShared: $includeShared\n\t\t) {\n\t\t\tresults {\n\t\t\t\t... on AbstractPage {\n\t\t\t\t\t__typename\n\t\t\t\t\tname\n\t\t\t\t\tpath\n\t\t\t\t}\n\t\t\t}\n\t\t\ttotal\n\t\t\tmoreResultsCountryUnique\n\t\t\tmoreResultsShared\n\t\t}\n\t}\n"): typeof import('./graphql').GetContentSlugsDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery GetProductSlugs($storeContext: StoreContextInput!, $pageSize: Int!, $fromId: String) {\n\t\tproducts(storeContext: $storeContext, fromId: $fromId, pageSize: $pageSize) {\n\t\t\tlastId\n\t\t\ttotal\n\t\t\tresults {\n\t\t\t\tslug\n\t\t\t}\n\t\t}\n\t}\n"): typeof import('./graphql').GetProductSlugsDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment ArticleCardFragment on ArticleCard {\n\t\tid\n\t\ttitle\n\t\tlocation\n\t\tpublishedAt\n\t\timage {\n\t\t\tfilename\n\t\t\talt\n\t\t}\n\t\tlink {\n\t\t\turl\n\t\t}\n\t}\n"): typeof import('./graphql').ArticleCardFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment ImageAsset on ContentAsset {\n\t\tfilename\n\t\talt\n\t\tfocus\n\t}\n"): typeof import('./graphql').ImageAssetFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment BreadcrumbFragment on Breadcrumb {\n\t\tname\n\t\tpath\n\t}\n"): typeof import('./graphql').BreadcrumbFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment CartSummaryFragment on Cart {\n\t\tsubTotal {\n\t\t\t...Price\n\t\t}\n\t\tdiscount {\n\t\t\tamount {\n\t\t\t\t...Price\n\t\t\t}\n\t\t}\n\t\ttotal {\n\t\t\t...Price\n\t\t}\n\t\tsubTotalBeforeDiscount {\n\t\t\t...Price\n\t\t}\n\t\tshippingCosts {\n\t\t\t...Price\n\t\t}\n\t}\n"): typeof import('./graphql').CartSummaryFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment CategoryCardFragment on Card {\n\t\t__typename\n\t\tid\n\t\ttitle\n\t\tcategory\n\t\tsupportingText\n\t\timage {\n\t\t\t...ImageAsset\n\t\t}\n\t\tcardLink: link {\n\t\t\turl\n\t\t\tnewTab\n\t\t\thash\n\t\t}\n\t}\n"): typeof import('./graphql').CategoryCardFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment FAQItemFragment on FAQItem {\n\t\t__typename\n\t\tid\n\t\tslug\n\t\ttitle\n\n\t\trichContent: content {\n\t\t\t...RichTextFragment\n\t\t}\n\t}\n"): typeof import('./graphql').FaqItemFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment FacetValueFragment on FacetValue {\n\t\tkey\n\t\tlabel\n\t\tcount\n\t}\n"): typeof import('./graphql').FacetValueFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment RangeFacetFragment on RangeFacet {\n\t\t__typename\n\t\tkey\n\t\tlabel\n\t\tmin\n\t\tmax\n\t\tselectedMin\n\t\tselectedMax\n\t}\n\n\tfragment OptionsFacetFragment on OptionsFacet {\n\t\t__typename\n\t\tkey\n\t\tlabel\n\t\toptions {\n\t\t\t...FacetValueFragment\n\t\t}\n\t}\n\n\tfragment ToggleFacetFragment on ToggleFacet {\n\t\t__typename\n\t\tkey\n\t\tlabel\n\t\toptions {\n\t\t\t...FacetValueFragment\n\t\t}\n\t}\n"): typeof import('./graphql').RangeFacetFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment FacetFragment on Facet {\n\t\t...RangeFacetFragment\n\t\t...OptionsFacetFragment\n\t\t...ToggleFacetFragment\n\t}\n"): typeof import('./graphql').FacetFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment HeroFragment on Hero {\n\t\t__typename\n\t\tid\n\t\ttitle\n\t\tvariant\n\t\timagePosition\n\t\timageStyle\n\t\tbodyText {\n\t\t\t...RichTextFragment\n\t\t}\n\t\tbuttons {\n\t\t\t...ButtonFragment\n\t\t}\n\t\timage {\n\t\t\t...ImageAsset\n\t\t}\n\t}\n"): typeof import('./graphql').HeroFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment LineItemSummaryFragment on LineItem {\n\t\tid\n\t\tquantity\n\t\tproductName\n\t\tvariant {\n\t\t\tname\n\t\t\tcolor\n\t\t\tsize\n\t\t\tprimaryImage {\n\t\t\t\turl\n\t\t\t\talt\n\t\t\t}\n\t\t}\n\t}\n"): typeof import('./graphql').LineItemSummaryFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment Price on Money {\n\t\tcentAmount\n\t\tcurrency\n\t}\n"): typeof import('./graphql').PriceFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment PriceTag on Money {\n\t\tcentAmount\n\t\tcurrency\n\t}\n"): typeof import('./graphql').PriceTagFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment ProductCard on Product {\n\t\tname\n\t\tslug\n\t\tneckline\n\t\trawMaterial\n\t\tbrand\n\t\tdenier\n\t\tfit\n\t\tpackSize\n\n\t\tribbon {\n\t\t\tkind\n\t\t\tlabel\n\t\t}\n\n\t\timage {\n\t\t\turl\n\t\t\talt\n\t\t}\n\n\t\tstartingPrice {\n\t\t\tgross {\n\t\t\t\t...PriceTag\n\t\t\t}\n\t\t}\n\n\t\tprimaryVariant {\n\t\t\t...ProductVariantAnalyticsFragment\n\t\t\tid\n\t\t\tsku\n\t\t\tavailability\n\t\t\tprice {\n\t\t\t\tnet {\n\t\t\t\t\t...PriceTag\n\t\t\t\t}\n\t\t\t\tgross {\n\t\t\t\t\t...PriceTag\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\n\t\tprimaryCategory {\n\t\t\t...CategoryAnalyticsFragment\n\t\t}\n\n\t\tvariants {\n\t\t\t...ProductVariantAnalyticsFragment\n\t\t}\n\n\t\t...ProductAnalyticsFragment\n\t}\n"): typeof import('./graphql').ProductCardFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment ProductListingConfig on ProductListingConfig {\n\t\tcategoryId\n\t\tcategoryPageId\n\t\tdefaultOrder\n\t\tenabledFacets\n\t}\n"): typeof import('./graphql').ProductListingConfigFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment ProductSearchResults on ProductSearchResults {\n\t\ttotal\n\t\ttotalPages\n\t\tresults {\n\t\t\t...ProductCard\n\t\t}\n\t\tfacets {\n\t\t\t...FacetFragment\n\t\t}\n\t}\n"): typeof import('./graphql').ProductSearchResultsFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment SectionHeaderFragment on SectionHeader {\n\t\t__typename\n\t\tid\n\t\ttitle\n\t\timagePosition\n\t\timageStyle\n\t\tbodyText {\n\t\t\t...RichTextFragment\n\t\t}\n\t\tbuttons {\n\t\t\t...ButtonFragment\n\t\t}\n\t\theaderImage: image {\n\t\t\t...ImageAsset\n\t\t}\n\t}\n"): typeof import('./graphql').SectionHeaderFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery GetProductCount(\n\t\t$storeContext: StoreContextInput!\n\t\t$sort: ProductSortOrder!\n\t\t$filters: [FacetFilterInput!]\n\t\t$searchTerm: String\n\t\t$categoryPageId: String\n\t) {\n\t\tproductSearch(\n\t\t\tstoreContext: $storeContext\n\t\t\tsort: $sort\n\t\t\tfilters: $filters\n\t\t\tcategoryPageId: $categoryPageId\n\t\t\tsearchTerm: $searchTerm\n\t\t) {\n\t\t\ttotal\n\t\t}\n\t}\n"): typeof import('./graphql').GetProductCountDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment TeaserRowFragment on TeaserRow {\n\t\t__typename\n\t\tid\n\t\ttitle\n\t\tviewMoreLink {\n\t\t\turl\n\t\t\tnewTab\n\t\t}\n\t\titems {\n\t\t\t__typename\n\t\t\tid\n\t\t\tcontent\n\t\t\timage {\n\t\t\t\t...ImageAsset\n\t\t\t}\n\t\t\tlink {\n\t\t\t\turl\n\t\t\t}\n\t\t\ttitle\n\t\t}\n\t}\n"): typeof import('./graphql').TeaserRowFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment UspsFragment on Usps {\n\t\tid\n\t\tusp1\n\t\tusp1Link {\n\t\t\turl\n\t\t\tnewTab\n\t\t\thash\n\t\t}\n\t\tusp2\n\t\tusp2Link {\n\t\t\turl\n\t\t\tnewTab\n\t\t\thash\n\t\t}\n\t\tusp3\n\t\tusp3Link {\n\t\t\turl\n\t\t\tnewTab\n\t\t\thash\n\t\t}\n\t}\n"): typeof import('./graphql').UspsFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment ArticleOverviewFragment on ArticleOverview {\n\t\t__typename\n\t\tid\n\t\tarticleItems: items(page: $currentPage) {\n\t\t\ttotalPages\n\t\t\tresults {\n\t\t\t\t...ArticleCardFragment\n\t\t\t}\n\t\t}\n\t}\n"): typeof import('./graphql').ArticleOverviewFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment BlockQuoteFragment on BlockQuote {\n\t\t__typename\n\t\tid\n\t\tcontent\n\t\tname\n\t\ttitle\n\t}\n"): typeof import('./graphql').BlockQuoteFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment ButtonFragment on Button {\n\t\t__typename\n\t\tid\n\t\ttitle\n\t\tvariant\n\t\tsize\n\t\tlink {\n\t\t\turl\n\t\t\tnewTab\n\t\t\thash\n\t\t}\n\t}\n"): typeof import('./graphql').ButtonFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment ButtonsFragment on Buttons {\n\t\t__typename\n\t\tid\n\t\tbuttons {\n\t\t\t...ButtonFragment\n\t\t}\n\t}\n"): typeof import('./graphql').ButtonsFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment CategorySectionFragment on CategorySection {\n\t\t__typename\n\t\tid\n\t\ttitle\n\t\tcategory\n\t\tsupportingText\n\t\theaderAlign\n\t\tvariant\n\t\tlink {\n\t\t\turl\n\t\t\tnewTab\n\t\t\thash\n\t\t}\n\t\titems {\n\t\t\t...CategoryCardFragment\n\t\t}\n\t}\n"): typeof import('./graphql').CategorySectionFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment ColumnBlockFragment on ColumnBlock {\n\t\t...DividerFragment\n\t\t...IframeFragment\n\t\t...ImageFragment\n\t\t...RichTextFragment\n\t\t...ButtonsFragment\n\t\t...BlockQuoteFragment\n\t\t...TableFragment\n\t\t...NewsletterCTAFragment\n\t\t...CookieDeclarationFragment\n\t\t...VideoFragment\n\t\t...FAQItemListFragment\n\t}\n"): typeof import('./graphql').ColumnBlockFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment FourColumnFragment on FourColumns {\n\t\t__typename\n\t\tid\n\t\talignment\n\t\tcolumn_1 {\n\t\t\t...ColumnBlockFragment\n\t\t}\n\t\tcolumn_2 {\n\t\t\t...ColumnBlockFragment\n\t\t}\n\t\tcolumn_3 {\n\t\t\t...ColumnBlockFragment\n\t\t}\n\t\tcolumn_4 {\n\t\t\t...ColumnBlockFragment\n\t\t}\n\t}\n"): typeof import('./graphql').FourColumnFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment ThreeColumnFragment on ThreeColumns {\n\t\t__typename\n\t\tid\n\t\talignment\n\t\tcolumn_1 {\n\t\t\t...ColumnBlockFragment\n\t\t}\n\t\tcolumn_2 {\n\t\t\t...ColumnBlockFragment\n\t\t}\n\t\tcolumn_3 {\n\t\t\t...ColumnBlockFragment\n\t\t}\n\t}\n"): typeof import('./graphql').ThreeColumnFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment TwoColumnFragment on TwoColumns {\n\t\t__typename\n\t\tid\n\t\talignment\n\t\tratio\n\t\tcolumn_1 {\n\t\t\t...ColumnBlockFragment\n\t\t}\n\t\tcolumn_2 {\n\t\t\t...ColumnBlockFragment\n\t\t}\n\t}\n"): typeof import('./graphql').TwoColumnFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment CookieDeclarationFragment on CookieDeclaration {\n\t\t__typename\n\t\tid\n\t}\n"): typeof import('./graphql').CookieDeclarationFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment CountrySpecificContainerFragment on CountrySpecificContainer {\n\t\t__typename\n\t\tid\n\t\tcountries\n\t\tcountrySpecificContainerBody: body {\n\t\t\t...BaseBlocksFragment\n\t\t}\n\t}\n"): typeof import('./graphql').CountrySpecificContainerFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment DividerFragment on Divider {\n\t\t__typename\n\t\tid\n\t}\n"): typeof import('./graphql').DividerFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment FAQItemListFragment on FAQItemList {\n\t\t__typename\n\t\tid\n\t\tfaqItems {\n\t\t\t...FAQItemFragment\n\t\t}\n\t}\n"): typeof import('./graphql').FaqItemListFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment IframeFragment on Iframe {\n\t\t__typename\n\t\tid\n\t\turl\n\t\theight\n\t}\n"): typeof import('./graphql').IframeFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment ImageFragment on Image {\n\t\t__typename\n\t\tid\n\t\timage {\n\t\t\t...ImageAsset\n\t\t}\n\t\taspectRatio\n\t}\n"): typeof import('./graphql').ImageFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment NewsletterCTAFragment on NewsletterCTA {\n\t\t__typename\n\t\tid\n\t\torientation\n\t}\n"): typeof import('./graphql').NewsletterCtaFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment RichTextFragment on RichText {\n\t\t__typename\n\t\tid\n\t\tcontent\n\t}\n"): typeof import('./graphql').RichTextFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment SectionFragment on Section {\n\t\t__typename\n\t\tid\n\t\tbackgroundColor\n\t\tsize\n\t\tspacing\n\t\tbody {\n\t\t\t...TeaserRowFragment\n\t\t\t...RichTextFragment\n\t\t\t...TwoColumnFragment\n\t\t\t...ThreeColumnFragment\n\t\t\t...FourColumnFragment\n\t\t\t...SectionHeaderFragment\n\t\t\t...ImageFragment\n\t\t\t...DividerFragment\n\t\t\t...IframeFragment\n\t\t\t...ButtonsFragment\n\t\t\t...BlockQuoteFragment\n\t\t\t...TableFragment\n\t\t\t...NewsletterCTAFragment\n\t\t\t...UGCFlowboxFragment\n\t\t\t...CookieDeclarationFragment\n\t\t\t...VideoFragment\n\t\t\t...FAQItemListFragment\n\t\t\t...ShopSectionFragment\n\t\t\t...CategorySectionFragment\n\t\t}\n\t}\n"): typeof import('./graphql').SectionFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment ShopSectionFragment on ShopSection {\n\t\t__typename\n\t\tid\n\t\ttitle\n\t\tsubtitle\n\t\tcategory\n\t\tviewAllLink {\n\t\t\turl\n\t\t\tnewTab\n\t\t}\n\t\tviewAllLabel\n\t\tvariant\n\t\tproducts {\n\t\t\t...ProductCard\n\t\t}\n\t}\n"): typeof import('./graphql').ShopSectionFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment TableCellFragment on ContentTableCell {\n\t\tvalue\n\t}\n\n\tfragment TableFragment on Table {\n\t\t__typename\n\t\tid\n\t\tstyle\n\t\tdividers\n\t\twidth\n\t\ttable {\n\t\t\tthead {\n\t\t\t\t...TableCellFragment\n\t\t\t}\n\t\t\ttbody {\n\t\t\t\tbody {\n\t\t\t\t\t...TableCellFragment\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"): typeof import('./graphql').TableCellFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment UGCFlowboxFragment on UGCFlowbox {\n\t\t__typename\n\t\tid\n\t\tkey\n\t\ttags\n\t}\n"): typeof import('./graphql').UgcFlowboxFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment VideoFragment on Video {\n\t\t__typename\n\t\tid\n\t\tvideo\n\t}\n"): typeof import('./graphql').VideoFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment Cart on Cart {\n\t\t...CheckoutSummaryFragment\n\t\tid {\n\t\t\tid\n\t\t\tversion\n\t\t}\n\t\tlineItems {\n\t\t\t...LineItem\n\t\t}\n\t\tsubTotal {\n\t\t\t...Price\n\t\t}\n\t\tdiscount {\n\t\t\tamount {\n\t\t\t\t...Price\n\t\t\t}\n\t\t}\n\t\ttotal {\n\t\t\t...Price\n\t\t}\n\t\tsubTotalBeforeDiscount {\n\t\t\t...Price\n\t\t}\n\t\tshippingCosts {\n\t\t\t...Price\n\t\t}\n\t\tshippingOption\n\t}\n"): typeof import('./graphql').CartFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment LineItem on LineItem {\n\t\tid\n\t\tquantity\n\t\tproductId\n\t\tproductSlug\n\t\tproductName\n\t\taddedAt\n\t\tavailableStock\n\t\tneckline\n\t\trawMaterial\n\t\tbrand\n\t\tdenier\n\t\tfit\n\t\tpackSize\n\t\tcategory {\n\t\t\tancestors {\n\t\t\t\tstoryblokName\n\t\t\t\tname\n\t\t\t}\n\t\t}\n\t\tregularPrice {\n\t\t\tgross {\n\t\t\t\t...Price\n\t\t\t}\n\t\t}\n\t\tprice {\n\t\t\tgross {\n\t\t\t\t...Price\n\t\t\t}\n\t\t\ttax {\n\t\t\t\tvalue {\n\t\t\t\t\t...Price\n\t\t\t\t}\n\t\t\t\trate\n\t\t\t}\n\t\t}\n\t\tdiscounts {\n\t\t\tcode\n\t\t\tvalue {\n\t\t\t\tcentAmount\n\t\t\t\tcurrency\n\t\t\t}\n\t\t\tdescription\n\t\t}\n\t\tvariant {\n\t\t\tname\n\t\t\tdescriptionShort\n\t\t\tsku\n\t\t\tsize\n\t\t\tcolor\n\t\t\tprimaryImage {\n\t\t\t\turl\n\t\t\t\talt\n\t\t\t}\n\t\t\torderConfiguration {\n\t\t\t\tmin\n\t\t\t\tmax\n\t\t\t\tstepSize\n\t\t\t}\n\t\t}\n\t}\n"): typeof import('./graphql').LineItemFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tmutation CartLineItemsAdd(\n\t\t$storeContext: StoreContextInput!\n\t\t$id: CartIdentifierInput\n\t\t$lineItems: [CartLineItemCreateInput!]!\n\t) {\n\t\tcartLineItemsAdd(storeContext: $storeContext, id: $id, lineItems: $lineItems) {\n\t\t\t...Cart\n\t\t}\n\t}\n"): typeof import('./graphql').CartLineItemsAddDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery GetCart($storeContext: StoreContextInput!) {\n\t\tcart(storeContext: $storeContext) {\n\t\t\t...Cart\n\t\t}\n\t}\n"): typeof import('./graphql').GetCartDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tmutation CartLineItemsUpdate(\n\t\t$storeContext: StoreContextInput!\n\t\t$id: CartIdentifierInput!\n\t\t$lineItems: [CartLineItemUpdateInput!]!\n\t) {\n\t\tcartLineItemsUpdate(storeContext: $storeContext, id: $id, lineItems: $lineItems) {\n\t\t\t...Cart\n\t\t}\n\t}\n"): typeof import('./graphql').CartLineItemsUpdateDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tmutation ProcessPaazlCheckout($storeContext: StoreContextInput!) {\n\t\tcheckoutUpdate(storeContext: $storeContext, processPaazlCheckout: true) {\n\t\t\t...Cart\n\t\t}\n\t}\n"): typeof import('./graphql').ProcessPaazlCheckoutDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment Address on Address {\n\t\tid\n\t\temail\n\t\tsalutation\n\t\tfirstName\n\t\tlastName\n\t\tstreetName\n\t\thouseNumber\n\t\thouseNumberSuffix\n\t\tpostalCode\n\t\tcity\n\t\tphone\n\t\tcountry\n\t\tcompany\n\t}\n"): typeof import('./graphql').AddressFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery GetCustomer {\n\t\tcustomer {\n\t\t\t...Customer\n\t\t}\n\t}\n"): typeof import('./graphql').GetCustomerDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment Customer on Customer {\n\t\tfirstName\n\t\tlastName\n\t\taccountType\n\t\tcompany\n\t\temail\n\t\tvatNumber\n\t\tbillingAddress {\n\t\t\t...Address\n\t\t}\n\t\tshippingAddress {\n\t\t\t...Address\n\t\t}\n\t\taddresses {\n\t\t\t...Address\n\t\t}\n\t}\n"): typeof import('./graphql').CustomerFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tmutation CustomerLogin($storeContext: StoreContextInput!, $email: String!, $password: String!) {\n\t\tcustomerLogin(storeContext: $storeContext, email: $email, password: $password) {\n\t\t\t...Customer\n\t\t}\n\t}\n"): typeof import('./graphql').CustomerLoginDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tmutation CustomerLogout($storeContext: StoreContextInput!) {\n\t\tcustomerLogout(storeContext: $storeContext)\n\t}\n"): typeof import('./graphql').CustomerLogoutDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment AddressValidationResult on AddressValidationResult {\n\t\tvalid\n\t\tsuggestions {\n\t\t\tcity\n\t\t\tcountry\n\t\t\thouseNumber\n\t\t\thouseNumberSuffix\n\t\t\tpostalCode\n\t\t\tstreetName\n\t\t}\n\t}\n"): typeof import('./graphql').AddressValidationResultFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery GetAddressValidate($storeContext: StoreContextInput!, $address: AddressInput!) {\n\t\taddressValidate(storeContext: $storeContext, address: $address) {\n\t\t\t...AddressValidationResult\n\t\t}\n\t}\n"): typeof import('./graphql').GetAddressValidateDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery GetProducts(\n\t\t$storeContext: StoreContextInput!\n\t\t$sort: ProductSortOrder!\n\t\t$filters: [FacetFilterInput!]\n\t\t$searchTerm: String\n\t\t$categoryPageId: String\n\t\t$pageSize: Int!\n\t\t$page: Int!\n\t) {\n\t\tproductSearch(\n\t\t\tstoreContext: $storeContext\n\t\t\tsort: $sort\n\t\t\tfilters: $filters\n\t\t\tcategoryPageId: $categoryPageId\n\t\t\tsearchTerm: $searchTerm\n\t\t\tpageSize: $pageSize\n\t\t\tpage: $page\n\t\t) {\n\t\t\t...ProductSearchResults\n\t\t}\n\t}\n"): typeof import('./graphql').GetProductsDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tfragment ProductVariantAnalyticsFragment on ProductVariant {\n\t\tname\n\t\tsku\n\t\tregularPrice {\n\t\t\tgross {\n\t\t\t\t...Price\n\t\t\t}\n\t\t}\n\t\tattributes {\n\t\t\tname\n\t\t\tvalue\n\t\t}\n\t}\n\n\tfragment CategoryAnalyticsFragment on ProductCategory {\n\t\tname\n\t\tid\n\t\tancestors {\n\t\t\tstoryblokName\n\t\t\tname\n\t\t}\n\t}\n\n\tfragment ProductAnalyticsFragment on Product {\n\t\tid\n\t\tbrand\n\t\tname\n\t}\n\n\tfragment CombinedProductAnalyticsFragment on Product {\n\t\t...ProductAnalyticsFragment\n\n\t\tprimaryCategory {\n\t\t\t...CategoryAnalyticsFragment\n\t\t}\n\n\t\tprimaryVariant {\n\t\t\t...ProductVariantAnalyticsFragment\n\t\t}\n\n\t\tvariants {\n\t\t\t...ProductVariantAnalyticsFragment\n\t\t}\n\t}\n"): typeof import('./graphql').ProductVariantAnalyticsFragmentFragmentDoc;


export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}
