import Cookie from "js-cookie";
import { decode as jwtDecode } from "jsonwebtoken";
import type { StoreConfig } from "../store-config/context";

type TokenBase = {
	sub: string;
	exp: number;
};

type UnauthenticatedToken = TokenBase & {
	authenticated: false;
};

type AuthenticatedToken = TokenBase & {
	authenticated: true;
	customerId: string;
	firstName: string;
	lastName: string;
	email: string;
	isBusinessCustomer: boolean;
};

export type Token = UnauthenticatedToken | AuthenticatedToken;

export const getJWT = (): Token | undefined => {
	const token = Cookie.get("authToken");
	if (!token) return undefined;

	return decodeToken(token);
};

export const getCustomerId = (token: AuthenticatedToken): string => {
	const [, id] = token.authenticated && token.sub.split("customer_id:");
	return id;
};

export const decodeToken = (token: string): Token | undefined => {
	const decodedToken = jwtDecode(token, { complete: true });
	if (!decodedToken || !decodedToken.payload || typeof decodedToken.payload === "string") {
		return undefined;
	}
	return decodedToken.payload as Token;
};

export const getAccessToken = async (
	apiHostname: string,
	locale: string,
	storeConfig: StoreConfig,
): Promise<Token | undefined> => {
	const token = getJWT();

	// Check if the token is still valid for more than 5 minutes. The JWT times
	// are in seconds, so we need to convert the current time to seconds as well.
	const timeSec = Math.floor(Date.now() / 1000);
	const buffer = 5 * 60;
	if (token?.exp && token.exp - buffer > timeSec) {
		return token;
	}

	// Do we have a refresh token?
	const hasRefreshToken = Cookie.get("authRefreshTokenExist");
	if (hasRefreshToken) {
		await refreshAccessToken(apiHostname, locale, storeConfig);
		return getJWT();
	}

	// No token exists
	return undefined;
};

export const refreshAccessToken = async (
	apiHostname: string,
	locale: string,
	storeConfig: StoreConfig,
): Promise<void> => {
	const query = "mutation { refreshToken { success } }";

	// Since we are storing the refresh token in a cookie this will be sent
	// automatically by the browser.
	const response = await fetch(
		`${apiHostname}/${locale.toLowerCase()}/auth/graphql?op=refreshToken`,
		{
			method: "POST",
			body: JSON.stringify({ query }),
			headers: {
				"Content-Type": "application/json",
				"X-StoreContext-StoreKey": storeConfig.storeKey,
				"X-StoreContext-Locale": locale,
				"X-StoreContext-Currency": storeConfig.currency,
			},
			credentials: "include",
		},
	);
	if (!response.ok) {
		throw new Error(`Failed to refresh token: ${response.statusText}`);
	}
};
